div.form {
    margin:0 0 0 0;
}
input.form-control,
textarea.form-control {
	line-height: 100%;
	width: 100%;
	border-radius: @radius;
	border: none;
	background-color: var(--re-grey-light);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	text-align: center;

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px var(--re-grey-light) inset;
	}
}
.form-group-lg textarea.form-control,
textarea.form-control {
    height:100px;
}

textarea, input { outline: none; }

input:active:focus {
	outline: none;
	outline-color: transparent;
	outline-style: none;
}
.form-group {
	padding-bottom: @gutter;
}

.ff-formular {
	@media (min-width: 768px) {
		[class*="col-"]:first-child {
			padding-left: 0;
		}
		[class*="col-"]:last-child {
			padding-right: 0;
		}
	}
}
.btn.btn-default {
	padding: 12px 30px 7px 30px;
	border: none;
	margin-bottom: 15px;
	background-color: var(--re-yellow);
	&:hover {
		background-color: #666666;
	}
}

/* Suchformular */

.ff-suchformular {
	input[type*="image"] {
		height: 16px;
	}
}

/* Switch */

.switch {
	position: relative;
	display: inline-block;
	input {
	  opacity: 0;
	  width: 0;
	  height: 0;
	}
	input:checked + .slider {
		background-color: var(--re-yellow);
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #000000;
	}

	input:checked + .slider:before {
		transform: translateX(26px);
	}

	/* The slider */
	.slider {
		position: absolute;
		height: 24px;
		width: 50px;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--re-yellow-highlight);
		transition: .4s;
		&:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 4px;
			bottom: 4px;
			background-color: var(--re-blue);
			transition: .4s;
		}

		/* Rounded sliders */
		&.round {
			border-radius: 24px;
		}
		&.round:before {
			border-radius: 50%;
		}
	}
}

.custom-select {
	width: 100%;
	position: relative;
}

.custom-select select {
	appearance: none;
	width: 100%;
	font-size: 1.15rem;
	padding: 0.675em 6em 0.675em 1em;
	background-color: var(--re-grey-light);
	border: none;
	border-radius: @radius;
	font-size: 16px;
	cursor: pointer;
}

.custom-select::before,
.custom-select::after {
	--size: 0.3rem;
	content: "";
	position: absolute;
	right: 1rem;
	pointer-events: none;
}

.custom-select::before {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-bottom: var(--size) solid var(--ff-dark);
	top: 40%;
}

.custom-select::after {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-top: var(--size) solid var(--ff-dark);
	top: 55%;
}


/********************
 **
 ** Custom Rentschler
 **
 ********************/
label {
	width: 100%;
}
.submit-button {
	display: flex;
	justify-content: center;
	padding-top: @gutter-x2;
}

.form-content {
	padding-top: @gutter-x2;
	padding-bottom: @gutter-x2;
}
.checkbox-label {
	color: var(--re-grey-fonts);
	a {
		text-decoration: underline;
	}
}
.form-switch-text {
	margin-top: -18px;
	padding-left: 60px;
	text-align: left;
}
.form-row {
	display: flex;
	.form-switch {
	}
}
.field-error {
	display: block;
	visibility: hidden;
	max-height: 0;
	transition: all 1s ease-in-out;
	color: var(--re-form-invalid-color);
	font-size: var(--font-size-small);
	padding-top: 5px;
	overflow: hidden;
}
.validation {
	select:invalid,
	input:invalid {
		~ .field-error {
			visibility: visible;
			max-height: 50px;
		}
	}
	.switch {
		.field-error {
			position: absolute;
			max-width: 300px;
			white-space: nowrap;
			padding-top: 12px;			
		}
	}
}

/********************
 **
 ** Download Formular
 **
 ********************/

.ff_re_downloadFormular {
	padding-bottom: @gutter-x4;
}