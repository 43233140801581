

 /********************
 **
 ** Photoswipe 
 **
 ********************/

 .custom-gallery {
	img {
		border-radius: @radius;
	}
 }

.custom-gallery {
	.pswp-thumb-container {
		display: inline-block;
		padding-right: @gutter-small;
		padding-bottom: @gutter-small;
	}
	
}


/********************
 **
 ** Login
 **
 ********************/


.login {
	.col-md-12 {
		label {
			display: block;
			float: left;
			width: 100px;
		}
		input {
			border: none;
			background: #eeeeee;
			width:200px;
			height: 28px;
		}
		button {
			width: 200px;
			height: 28px;
			margin-left: 100px;
			background: var(--re-yellow);
			border: none;
			border-radius: @radius;
		}
	}
}


/********************
 **
 ** Details
 **
 ********************/

 .details-group {
	border-top: var(--re-grey-middle) .1rem solid;		
 }
 .akkordion::part(base) {
	border: none;
	border-radius: 0;
	border-bottom: var(--re-grey-middle) .1rem solid;
}

 .schema-blue {
	.details-group {
		border-color: var(--re-white);
	}
	.akkordion::part(base) {
		border-color: var(--re-white);
	}
}
.details-group .akkordion[open]::part(header) {
	font-weight: bold;
}



/********************
 **
 ** Image Link
 **
 ********************/


div.image-link {
	div.normal {
		display:inline-block;
		background-size:cover;
	}
	div.hover {
		display: none;
		background-size:cover;
	}
	a:hover {
		div.normal {
			display: none;
		}
		div.hover {
			display:inline-block;
		}
	}
}

/********************
 **
 ** Footerlist
 **
 ********************/

.footerList {
	margin:0;
	padding: 0;
	list-style-type: none;
	a {
		color:var(--re-white);
		&:hover {
			color: var(--re-yellow);
		}
	}
}
.footer-items {
	h4 {
		color: var(--re-white);
	}
}
@media screen and (max-width: @break-md) {
	.footer-grid {
		row-gap: 0;
		border-top: white 1px solid;
	}
	.footer-items {
		padding-top: @gutter;
		border-bottom: white 1px solid;
		h4 {
			cursor: pointer;
		}
		.footerList { 
			padding-bottom: @gutter;
		}
		&.hidden {
			.footerList {
				display: none;
			}
		}
	}		
}


/********************
 **
 ** News
 **
 ********************/



 /********************
 **
 ** Carousel
 **
 ********************/

 sl-carousel::part(pagination-item--active) {
	transform: scale(1);
	background-color: var(--re-blue);
} 

 .schema-blue {
	sl-carousel::part(pagination-item--active) {
		transform: scale(1);
		background-color: var(--re-white);
	} 
	 
}

.carousel-container-hero {
	margin-bottom: @gutter-x4;
	background-size: cover;
	sl-carousel {
		position: relative;
	}
	sl-carousel::part(pagination) {
		position: relative;
		top: @gutter-x2;
	}
	.carousel-image {
		padding-top: @gutter;
		img {
			border-radius: @radius-16;
		}
	}
	.carousel-content {
		padding-top: @gutter;
	}
	h1, h2, h3, h4, h5, h6, p, li {
		color: var(--re-white);
	}
}

@media screen and (min-width: @break-md) {
	.carousel-container-hero {
		margin-bottom: @gutter-x6;
		sl-carousel::part(pagination) {
			position: relative;
			top: @gutter-x2;
		}
		.carousel-content {
			padding-right: 2rem;
		}
	}
}
@media screen and (min-width: @break-lg) {
	.carousel-container-hero {
		margin-bottom: @gutter-x10;
		sl-carousel {
			aspect-ratio: auto;
		}
	}
}
@media screen and (min-width: @break-xl) {
	.carousel-container-hero {
		height: 490px;
		sl-carousel {
			top: @gutter-x4;
		}
		sl-carousel::part(pagination) {
			position: absolute;
			left: 0;
			top:auto;
			bottom: @gutter;
		}
		.carousel-image {
			padding-top: 0;
		}
	}
}
@media screen and (min-width:@break-xxl) {
	.carousel-container-hero {
		height: 560px;
	}
} 

/********************
 **
 ** Carousel Content
 **
 ********************/

 .carousel-container-content {
	padding-top: @gutter-x2;
	padding-bottom: @gutter-x2;	
 }

.carousel-content-image {
	width: 100%;
	border-radius: @radius-16;
	aspect-ratio: 16/9;
	overflow: hidden;
	img {
		object-fit: cover;
		object-position: center;
	}
	iframe {
		border-radius: @radius;
		overflow: hidden;
	}
}
.carousel-content::part(navigation-button--previous)  {
	color: var(--re-grey-fonts);
}
.carousel-content::part(navigation-button--next)  {
	color: var(--re-grey-fonts);
}
/*
.schema-blue {
	.carousel-content::part(navigation-button--previous)  {
		color: var(--re-white);
	}
	.carousel-content::part(navigation-button--next)  {
		color: var(--re-white);
	}
}
*/

.carousel-single-item {
	padding-top: @gutter-x4;
}

.carousel-container-content {
	sl-carousel::part(base) {
		gap: 0;
	}

	sl-carousel::part(pagination-item--active) {
		transform: scale(1);
	} 

	sl-carousel::part(navigation) {
		position: relative;
	}
	sl-carousel::part(pagination) {
		transform: translateY(16px);
	}
	
	sl-carousel::part(navigation-button)  {
		position: absolute;
		top:0;
		z-index: 10;
		background: rgba(255,255,255,0.3);
		height: calc(100% - var(--sl-spacing-small) );
		width: 4rem;
		justify-content: center;
		transition: all 0.3s ease-in-out;
		color: var(--re-blue);
		font-size: 4rem;
		&:hover {
			background: rgba(255,255,255,0.6);
		}
	}
	
	sl-carousel::part(navigation-button--next)  {
		right: 0;
		border-top-right-radius: @radius-16;
		border-bottom-right-radius: @radius-16;
	}
	sl-carousel::part(navigation-button--previous)  {
		border-top-left-radius: @radius-16;
		border-bottom-left-radius: @radius-16;
	}
}

/********************
 **
 ** Cards
 **
 ********************/

/* Simple Cards */

.card {
	border-radius: @radius;
	border: var(--re-grey-middle) solid 1px;
	margin-bottom: @gutter-x2;
	overflow: hidden;
	&.widget {
		min-height: 300px;
	}
}
.card-content {
    padding: @gutter-x2;
}

.card-img {
	img {
        width: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }
}
.card-svg {
	width: 20px;
	height: 20px;
	svg {
		fill: var(--re-blue);
	}
}
.card-list {
	list-style-type: none;
	margin: 0;
	padding: 0 @gutter-x2 @gutter-x2 @gutter-x2;
}
.card-list-item {
	display: flex;
	justify-content: space-between;
	border-bottom:  var(--re-grey-middle) solid 1px;
	padding: 8px 0;
	&:first-child {
		border-top:  var(--re-grey-middle) solid 1px;
	}
}

/* Teaser Cards */

 .card-container {
	padding-bottom: @gutter-x6;
 }

 .card-header {
	font-size: var(--font-size);
	line-height: var(--s-1);
 }
 .card-number,
 .card-counter {
	font-family: var(--re-font-univers-condensed);
	font-size: var(--font-size-7);
	color: var(--re-blue);
	white-space: no-wrap;
 }
 .card-footer {
	font-size: var(--font-size);
	line-height: 1.2;
	min-height: 3rem;
 }
.card-icon {
	width: 20%;
	margin-bottom: @gutter;
	path {
		fill: var(--re-blue);
	}
}

.col-lg-3 {
	.card-icon {
		width: 30%;
	}	
}

.col-lg-4 {
	.card-icon {
		width: 20%;
	}	
}

.col-lg-6 {
	.card-icon {
		width: 30%;
	}	
}

@media screen and (min-width: @break-lg) {
	.col-lg-3 {
		.card-icon {
			width: 30%;
		}	
	}
	.col-lg-4 {
		.card-icon {
			width: 50%;
		}	
	}
	.col-lg-6 {
		.card-icon {
			width: 30%;
		}	
	}
}	

@media screen and (min-width: @break-xl) {
	.col-lg-4 {
		.card-icon {
			width: 40%;
		}	
	}
}	

@media screen and (min-width: @break-xxl) {
	.col-lg-4 {
		.card-icon {
			width: 30%;
		}	
	}
}
 .card-text {
	font-family: var(--re-font-univers);
	font-size: var(--font-size-2);
	line-height: 1.2;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;		
}

.re-card {
	flex: 0 0 100%;
	position: relative;
	border-radius: @radius-20;
	border: var(--re-grey-middle) solid 1px;
	overflow: hidden;
	margin-bottom: @gutter-x2;
/*	&.rowspan-2 {
		height: 620px;
	}
	&.card-item-element {
	}
*/	
	.re-card-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		&.card-icon {
			padding: @gutter-x2;
			justify-items: center;
			text-align: center;
		}
		&.card-facts {
			text-align: center;
		}
		&.card-text {
			padding: @gutter-x2;
			text-align: center;
		}
		&.card-richtext {

		}
		&.card-bgbild {
			display: grid;
			justify-items: center;
			> * {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}	
			.card-bg {
				width: 100%;
				height: 100%;
			}
			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
			.card-footer {
				z-index: 1;
				align-self: end;
				margin-bottom: 16px;
			}
		 }
	}

	.card-number {
		position: relative;
	}
	&.schema-yellow {
		background-color: var(--re-yellow);
		border: none;
		.card-header {
		}
		.card-number {
			color: var(--re-grey-fonts);
		}
		.card-footer {
	   
		}
		.card-icon {
			path {
				fill: var(--re-grey-fonts);
			}
			   
		}
		.card-text {
	   
		}
	   
	}
	&.schema-blue {
		background-color: var(--re-blue);
		border: none;
		.card-header {
			color: var(--re-white);
		}
		.card-number {
			color: var(--re-white);
		}
		.card-footer {
			color: var(--re-white);
		}
		.card-icon {
			path {
				fill: var(--re-white);
			}
		}
		.card-text {
			color: var(--re-white);
		}
	   
	}
	&.schema-bg {
		.card-footer {
			position: absolute;
			bottom: @gutter;
			background: var(--re-white-80);
			padding: @gutter;
			border-radius: @radius;
			width: 80%;		
		}
	}
}

.card-bild {
	width: 100%;
	border-radius: @radius-20;
	aspect-ratio: 16/9;
	&.aspect-square {
		aspect-ratio: 1/1;
	}
	img {
		width: 100%;
		height: 100%;
		border-top-left-radius: @radius-20;
		border-top-right-radius: @radius-20;
		object-fit: cover;
		object-position: center;
	}
}
.card-richtext {
	width: 100%;
	text-align: left;
	padding-block: @gutter;
	padding-inline: @gutter;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;	
	.btn {
		display: inline-block;
		position: relative;
		top: @gutter;
	}
	h4 {
		color: var(--re-blue);
	}
}

.news-teaser {
	margin-top: @gutter-x6;
	margin-bottom: @gutter-x6;
}

.news-teaser,
.news-filter-list {
	.re-card {
		@media screen and (min-width: @break-md) {
			.teaser-content-flex {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;
				width: 100%;
			}
		}
		&.imageLeft {
			.re-card-content {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
			}
			.card-image {
				width: 50%;
				height: 100%;
			}
			.teaser-content-flex {
				width: 50%;
				height: 100%;
			}
			.card-footer {
				left: calc(50% + @gutter-x2);
			}			
		}
		&.lg {
			.card-image {
				aspect-ratio: 16/4.3;
			}
		}
		&.imageLeft.lg {
			.card-image {
				aspect-ratio: 16/9;
			}
		}

	}
}

a {
	.re-card-content {
		&:hover {
			.hoverlay {
				visibility: visible;
				opacity: 1;			
			}
		}
	}
}
.card-image {
	aspect-ratio: 16/9;
	width: 100%;
	overflow: hidden;
	display: grid;
	picture,
	> * {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	.hoverlay {
		padding: @gutter;
		visibility: hidden;
		opacity: 0;
		z-index: 1;
		background-color: var(--re-blue-90);
		transition: all 0.25s ease-in-out;
		display: grid;
		place-items: center end;
		color: var(--re-white);		
		cursor:pointer;
		svg {
			fill: var(--re-white);
		}
	}	

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
.news-info {
	margin: 0;
	margin-bottom: @gutter;
}
.teaser-text {
	p {
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.news-item {
	color: var(--re-grey-middle);
	font-size: var(--font-size-small);
	svg {
		height: 1rem;
		margin-right: .4rem;
		fill: var(--re-grey-middle);
	}
}
.card-teaser {
	font-size: var(--font-size);
	text-transform: none;
	padding: @gutter;
}
.card-header,
.card-footer {
	padding: @gutter;
}


/********************
 **
 ** Text Icon Block
 **
 ********************/
.icon-textblock {
	display: flex;
	margin-bottom: @gutter-x2;
	.icon-svg {
		width: 20%;
		padding-right: @gutter-x2;
	}
	.text {
		width: 80%;
	}

}


/********************
 **
 ** Hero
 **
 ********************/

.hero {
    position: relative;
    width: 100%;
	padding: 0;
	overflow: hidden;
}

.hero-image {
	aspect-ratio: 16/6;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center right;
	}
}
.hero-elipse {
	display: none;
}

.hero-content {
	padding-top: @gutter-x4;
	padding-bottom: @gutter-x4;
	background: transparent linear-gradient(180deg, #00386A 0%, #006ab2 100%) 0% 0% no-repeat padding-box;
}

.hero-image-only {
	width: 100%;
	height: 100%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}


@media screen and (min-width: @break-lg) {
	.hero {
		aspect-ratio: 16/8;
	}
}

@media screen and (min-width: @break-xl) {
	.hero {
		aspect-ratio: 16/6;
	}
}

@media screen and (min-width: @break-xxl) {
	.hero {
		aspect-ratio: 16/5;
	}
}

@media screen and (min-width: @break-lg) {
	.hero-image {
		aspect-ratio: auto;
		width: 60%;
		height: 100%;
		margin-left: 40%;
	}

	.hero-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center right;
	}
	.hero-elipse {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 60%;
		height: 100%;
		overflow: hidden;
	}
	.hero-elipse:after {
		content:"";
		width: 2000px;
		height: 2000px;
		position: absolute;
		right: 0;
		top:50%;
		transform: translate(0,-50%);
		border-radius: 50%;
		background-color: lightblue;
		background: transparent linear-gradient(180deg, #00386A 0%, #006ab2 100%) 0% 0% no-repeat padding-box;
	}

	.hero-content {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 2;
		padding-top: @gutter-x6;
		background: none;
	}

	.hero-image-only {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
/********************
 **
 ** Linkbox/Linkpool
 **
 ********************/

.linkbox {
	margin-bottom: @gutter-x6;
 }
.img-area {
	position: relative;
	min-height: 520px;
	height: 100%;
	display: flex;
	justify-content: start;
	align-items: end;
	background-size: cover;
	background-repeat: no-repeat;
	&.area-left {
		border-right: solid 1px var(--re-grey-middle);
		border-left: solid 1px var(--re-grey-middle);
		border-top: solid 1px var(--re-grey-middle);
		border-top-left-radius: @radius-20;
		border-top-right-radius: @radius-20;
	}
	&.area-right {
		border-right: solid 1px var(--re-grey-middle);
		border-left: solid 1px var(--re-grey-middle);
		border-top: solid 1px var(--re-grey-middle);
		border-top-left-radius: @radius-20;
		border-top-right-radius: @radius-20;
	}
}

@media screen and (min-width: @break-lg) {
	.img-area {
		&.area-left {
			border-right: none;
			border-bottom: solid 1px var(--re-grey-middle);
			border-left: solid 1px var(--re-grey-middle);
			border-top: solid 1px var(--re-grey-middle);
			border-top-left-radius: @radius-20;
			border-bottom-left-radius: @radius-20;
			border-top-right-radius: 0;
	
		}
		&.area-right {
			border-left: none;
			border-bottom: solid 1px var(--re-grey-middle);
			border-right: solid 1px var(--re-grey-middle);
			border-top: solid 1px var(--re-grey-middle);
			border-top-right-radius: @radius-20;
			border-bottom-right-radius: @radius-20;
			border-top-left-radius: 0;
		}
	}
	
}

.link-area {
	list-style: none;
	min-height: 520px;
	height: 100%;
	padding: @gutter-x2;
 
	&.area-left {
		border-bottom: solid 1px var(--re-grey-middle);
		border-left: solid 1px var(--re-grey-middle);
		border-right: solid 1px var(--re-grey-middle);
		border-bottom-right-radius: @radius-20;
		border-bottom-left-radius: @radius-20;
	}
	&.area-right {
		border-bottom: solid 1px var(--re-grey-middle);
		border-left: solid 1px var(--re-grey-middle);
		border-right: solid 1px var(--re-grey-middle);
		border-bottom-right-radius: @radius-20;
		border-bottom-left-radius: @radius-20;
	}
}

@media screen and (min-width: @break-lg) {
	.link-area {
		&.area-left {
			border-right: 0;
			border-bottom: solid 1px var(--re-grey-middle);
			border-left: solid 1px var(--re-grey-middle);
			border-top: solid 1px var(--re-grey-middle);
			border-top-left-radius: @radius-20;
			border-bottom-left-radius: @radius-20;
			border-bottom-right-radius: 0;
		}
		&.area-right {
			border-left: 0;
			border-bottom: solid 1px var(--re-grey-middle);
			border-right: solid 1px var(--re-grey-middle);
			border-top: solid 1px var(--re-grey-middle);
			border-top-right-radius: @radius-20;
			border-bottom-right-radius: @radius-20;
			border-bottom-left-radius: 0;
		}
	}
}

.linkbox-content {
	padding: 32px;
	margin: @gutter-x2;
	background: #ffffffcc;
	border-radius: 10px;
}	
@media screen and (min-width: @break-xl) {
	.linkbox-content {
		width: 70%;
	}
}
.linkbox-item {
	a {
		display: block;
		&:hover {
			color: var(--re-blue);
		}
	}
	.link-headline {
		svg {
			fill: var(--re-blue);
			margin-top: -16px;
		}
	}
	.link-headline {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.link-content {
		padding-right: 10%;
	}
	h4, p {
		margin-bottom: 8px;
	}
 }


 /********************
 **
 ** Portrait
 **
 ********************/
.portrait-wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: @gutter-x2;

	.round {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 214px;
		height: 214px;
		border-radius: 50%;
		background: transparent linear-gradient(133deg, #FFE00019 0%, var(--re-yellow) 75%, var(--re-yellow) 100%) 0% 0% no-repeat padding-box;    
	}
	.inner {
		width: 175px;
		height: 175px;
		border-radius: 50%;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
/********************
 **
 ** Boxed Content
 **
 ********************/
.boxed-content {
	display: flex;
	justify-content: center;
}
.boxed {
	border-radius: @radius-20;
	overflow: hidden;
	background-color: #ffffffee;
	padding-block: @gutter-x6 @gutter-x4;
	padding-inline: @gutter-x4;
	border: 1px solid var(--re-grey-middle);
}
@media screen and (min-width: @break-lg) {
	.boxed {
		border: none;
		&.center {
			width: 70%;
		}
		&.left {
			width: 50%;
			margin-right: 50%;
		}
	}
}

/********************
 **
 ** Header Message
 **
 ********************/

#header-message {
	position: relative;
	display: none;
	background-color: var(--re-yellow);

	&.show {
		display: block;
	}

	.align-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
		@media screen and (max-width: 759px) {
			padding-right: 40px;
		}
	}

	.message-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-top: @gutter;
		padding-bottom: @gutter;

		p, h1, h2, h3, h4, h5, h6 {
			margin: 0;
		}
	}

	.message-more {
		display: block;
		padding: 12px 20px 8px 20px;
		border-radius: @radius;
		background-color: var(--re-yellow-highlight);
	}
}

/********************
 **
 ** Breadcrumb
 **
 ********************/
 .breadcrumb {
	border-bottom: 1px var(--re-grey-between) solid;
 }
 .breadcrumb-list {
	padding: 8px 0 4px 0;
	display: flex;
	list-style-type: none;
	margin: 0;
	.seperator::before {
		content: ' > ';
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
	}
	li, a {
		font-size: var(--font-size-small);
		color: var(--re-grey-between);
	}
	.leaf {
		display: none;
	}
	@media screen and (min-width: @break-lg) {
		.leaf {
			display: block;
		}
	}

	a:hover {
		color: var(--re-grey-fonts);
	}
}

/********************
 **
 ** Row Image und Text
 **
 ********************/

.row-image-text {
	&.left {
		display: flex;
		flex-direction: column-reverse;		
	}
}
.col-text-container {
    width: 100%;
	.row-text {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: @gutter-x2;
	}
}
.col-img {
	padding: @gutter-x2;
	img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}
.img-wrapper {
	border-radius: @radius;
	aspect-ratio: 16/9;
	overflow: hidden;
}

@media screen and (min-width: @break-lg) {
	.col-img {
		width: 50%;
		padding: 0;
		.img-wrapper {
			border-radius: 0;
		}
		&.left {
			padding-right: @gutter-x4;
			.img-wrapper {
				border-top-right-radius: @radius;
				border-bottom-right-radius: @radius;
			}
		}
		&.right {
			padding-left: @gutter-x4;
			.img-wrapper {
				border-top-left-radius: @radius;
				border-bottom-left-radius: @radius;
			}
		}
	}
	.row-image-text {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		&.left {
			flex-direction: row;
		}
	}
	.col-text-container {
		width: 480px;
		&.left {
			width: 50%;
			padding-left: calc(50% - 480px);
		}
		.row-text {
			padding: @gutter;
		}
	}
}
@media screen and (min-width: @break-xl) {
	.col-text-container {
		width: 570px;
		&.left {
			padding-left: calc(50% - 570px);
		}
	}
}

@media screen and (min-width: @break-xxl) {
	.col-text-container {
		width: 660px;
		&.left {
			padding-left: calc(50% - 660px);
		}
	}
}

/********************
 **
 ** Card with page turn 
 **
 ********************/

.flip-card {
	width: 100%;
	margin-bottom: @gutter-x2;
	border-radius: @radius;
	border: 1px solid var(--re-grey-middle);
	overflow: hidden;
}

 .flip-card-front, .flip-card-back {
	background-color: var(--re-white);
}

.flip-card-front {
	padding-bottom: @gutter;	
	h3, h4, h5, h6 {
		color:var(--re-blue);
	}
}
.flip-card-back {
	padding: @gutter;
}
.card-social-icons {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	gap: 10px;
	li {
		width: 18px;
		path {
			fill: var(--re-blue);
		}
	}
}

.img-pageflip {
	width: 100%;
}
.flip-card-front {
	.card-richtext {
		justify-content: start;
		.btn-flip {
			display: none;
		}
	}
}

 @media screen and (min-width: @break-md) { 
	.flip-card-front {
		.card-richtext .btn-flip {
			display: block;
			position: absolute;
			bottom: @gutter;
			top: auto;
		}
	}

	
	.flip-card {
		background-color: transparent;
		width: 100%;
//		aspect-ratio: 3 / 4.4;
		perspective: 1000px;
		margin-bottom: 0;
		padding-bottom: 26px;
		border-radius: 0;
		border: none;
		overflow:visible;
		&.ratio-1-1 {
//			aspect-ratio: 1 / 1;
		}
		&.ratio-1-8 {
//			aspect-ratio: 1 / 2;
		}
	}
	
	.flip-card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		transition: transform 0.8s;
		transform-style: preserve-3d;
	}
	
	.flip-card.flip .flip-card-inner {
		transform: rotateY(180deg);
	}
	
	.flip-card-front, .flip-card-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		border-radius: @radius;
		border: 1px solid var(--re-grey-middle);
		overflow: hidden;
	}
	
	.flip-card-front {
		display: flex;
		flex-direction: column;
	}
	
	.flip-card-back {
		max-height: 650px;
		min-height: 250px;
		background-color: var(--re-white);
		transform: rotateY(180deg);
		box-shadow: 10px 10px 50px #00000010;
		position: relative;
		padding: 0;
		padding-block: @gutter-x4 @gutter-x2;
		padding-inline: @gutter-x2;
	}
	
	.img-pageflip {
		height: auto;
		aspect-ratio: 1/1;
		object-fit: cover;
	}

 }
	/********************
 **
 ** News Filter 
 **
 ********************/
.searchbar-natural,
.news-filter {
	position: relative;
	width: 100%;
	height: 450px;
	filter:blur(0);
	transition: 0.25s all ease-in-out;
	&.visible {
		.natural-language-main-list {
			filter:blur(4px);
		}
		.natural-language-overlay {
			&.active {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				height: 100%;
			}
		}
	}
}
.natural-language-main-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	li {
		margin: .5rem;
		padding: 0;
		font-family: var(--re-font-univers-condensed);
		font-size: var(--font-size-5);
		font-weight: var(--re-font-weight-normal);
		color: var(--re-white);
			a {
				font: inherit;
				color: var(--re-yellow);
				border-bottom: 2px solid var(--re-yellow);

		}
	}
}
@media screen and (min-width: @break-md) {
	.natural-language-main-list {
		flex-direction: row;
	}
}
.suggestionList,
.natural-language-select {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: @gutter;
	list-style-type: none;
	overflow-y:auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	&::-webkit-scrollbar-track {
		background: #fff;
	}
	&::-webkit-scrollbar {
		width: 2px;
	}
	&::-webkit-scrollbar-thumb {
		background: #999 !important;
	}	
	li {
		font-family: var(--re-font-univers-condensed);
		font-size: var(--font-size-5);
		font-weight: var(--re-font-weight-normal);
		line-height: 1;
		color: var(--re-white);
		cursor: pointer;
		word-break: break-all;
		&.selected {
			color: var(--re-yellow);
		}

		span.fit{
			font-weight: bold;
		}
	}
}

.natural-language-overlay {
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 0;
	padding: 28px;
	opacity: 0;
	filter:blur(0);	
	transform: translate3d(0, -450px, 0);
	transition: 0.25s all ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
}
.natural-language-tools {
	position: absolute;
	top: @gutter;
	right: @gutter;
	width: 12px;
	height: 12px;
	a {
		display: block;
		height: @gutter;
		width: @gutter;
		cursor: pointer;
	}
}
.search-controls {
	position: relative;
	display: flex;
	flex-direction: column;
	border-bottom: 2px solid var(--re-white);
	@media screen and (min-width: @break-md) {
		width: 60%;
	}
	input {
		background: transparent;
		border: none;		
		font-family: var(--re-font-univers-condensed);
		font-size: var(--font-size-5);
		font-weight: var(--re-font-weight-normal);
		color: var(--re-white);
	}
}
.search-control-icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	display: block;
	width: 32px;
	height: 23px;
}
.suggestionList {
	position: absolute;
	min-height: 300px;
	top: 56px;
	background-color: #00499270;
	border-bottom-left-radius: @radius;
	border-bottom-right-radius: @radius;
	align-items: normal;
}
/********************
 **
 ** Table
 **
 ********************/


  
table {
	text-align: left;
	border-collapse: collapse;
	width: 100%;
	border-width: 0;
}

thead th {
	vertical-align: top;
}

thead td,
thead td {
	font-weight: 600;
}

thead {
	border-top: 1px solid var(--re-grey-fonts);
	border-bottom: 1px solid var(--re-grey-between);
}

tbody tr {
	border-bottom: 1px solid var(--re-grey-between);
}

tbody td::before {
	display: none;
}

table td {
	padding: 8px;
}

@media screen and (max-width: 760px) {
	thead {
		display: none;
	}

	table,
	tbody,
	tbody tr,
	tbody td,
	caption {
		width: auto !important;
		word-break: break-all;
	}

	table {
		background-color: transparent;
		height: auto !important;
	}

	table tr {
		border-bottom: none;
		border-top: 1px solid var(--re-grey-fonts);
		border-bottom: 1px solid var(--re-grey-fonts);
		height: auto !important;
	}
	table td {
		height: auto !important;
	}

	tbody td {
		border-bottom: 1px solid var(--re-grey-between);
		width: auto !important;
		height: auto !important;
	}

	.table-wrapper {
		max-width: 568px;
	}

	tbody tr td::before {
		font-weight: 600;
		display: block;
		align-self: baseline;
	}
	.schema-blue {
		tbody td {
			border-bottom: 1px solid var(--re-white);
		}
		table tr {
			border-top: none;
			border-bottom: none;
		}		
	}
}

/********************
 **
 ** Mega Card
 **
 ********************/

.mega-card {
	background-color: var(--re-grey-light);
	border-radius: @radius-20;
	padding: @gutter-x4;
}
.mega-card-img {
	max-width: 100%;
}

/********************
 **
 ** timeline
 **
 ********************/
  
  .swiper-container {
	position: relative;
	width: 100%; 
	margin: 0 auto;
	overflow-y: auto;
	touch-action: pan-x;
	.swiper {
		position: relative;
	}
	.btn-scroll {
		position: absolute;
		z-index: 1;
		width: 40px;
		height: 100%;
		background-color: #ffffff99;
		svg {
			width: 40px;
			height: 40px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			fill: var(--re-blue);
		}
	}
	.prev {
		left: 0;
	}
	.next {
		right: 0;
	}	
  }
  .swiper-wrapper{
	display: inline-flex;
	flex-direction: row;
	gap: @gutter;
	overflow-y:auto;
	justify-content: center;
	scroll-snap-type: x mandatory;
	scroll-snap-align: center;
	scroll-snap-stop: always;
	padding: 20px;
  }
  .swiper-container::-webkit-scrollbar-track{
	 background:#a8a8a8b6;
  }
  .swiper-container::-webkit-scrollbar{
	height: 2px;
  }
  .swiper-container::-webkit-scrollbar-thumb{
	 background: #4F4F4F !important;
  }
  .swiper-slide {
	position: relative;
	width: 330px;
	margin-bottom: @gutter;
	.re-card {
		position: relative;
		height: 90%;
		padding-bottom: @gutter;
		overflow: visible;
		cursor: pointer;
	}
	.timeline {
		border-bottom: 1px solid #eee;
		position: absolute;
		bottom: 0;
		left: -8px;
		width: calc(100% + @gutter);
		&::after {
			position: absolute;
			left: 50%;
			top: -6px;
			content: '';
			border-radius: 16px;
			width: 12px;
			height: 12px;
			border:1px solid #eee;
			background-color: #fff;
			transform: translateX(-50%);
		}
		&.start {
			width: calc(50% + @gutter);
			left: calc(50% - 6px);
			&::after {
				left: 0;
				transform: translateX(0);
			}
		}
		&.end {
			width: calc(50% + @gutter);
			left: -10px;
			&::after {
				right: 0;
				left: auto;
				transform: translateX(0);
			}
		}
	}
	&:hover {
		.re-card {
			border-color: #fff;
			box-shadow: 10px 10px 30px #00000020;
			&::after {
				content: '';
				position: absolute;
				bottom: -20px;
				left: 50%;
				transform: translateX(-50%);
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 20px solid #fff;
				box-shadow: 10px 10px 30px #00000020;
			}
		}
		.timeline {
			&::after {
				border-color: #00386A;
				background-color: #00386A;
			}
		}
		h4, h5 {
			color: var(--re-blue);
		}
	}
  }

/********************
 **
 ** Events
 **
 ********************/

.event-filter-list {
	list-style: none;
	margin-bottom: @gutter-x2;
	padding-left: 0;
	padding-top: @gutter;
	padding-bottom: @gutter;
	border-top: 1px solid var(--re-grey-light);
	border-bottom: 1px solid var(--re-grey-light);
}
.event-element {
	display: flex;
	padding-bottom: @gutter;
	margin-bottom: @gutter;
	border-bottom: 1px solid var(--re-grey-light);
}
.event-element:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.event-dates {
	width: 10%;
	display: flex;
	flex-direction: column;
	padding-top: 14px;
}
.event-day {
	font-family: var(--re-font-univers-condensed);
	font-size: var(--font-size-5);
	color: var(--re-blue);
	line-height: 0.5;
}
.event-month-year {
	font-family: var(--re-font-univers);
	font-size: var(--font-size-small);
	color: var(--re-blue);
}
.event-teaser {
	width: 80%;
	display: grid;
	align-items: center;
	grid-template-rows: 1fr;
}
.event-title {

}
.event-info {

}
.event-location {

}
.event-post-date {

}
.event-url {
	width: 10%;
	display: flex;
	justify-content: end;
    align-items: center;
    align-self: stretch;	
	svg {
		fill: var(--re-blue);
	}
}

/********************
 **
 ** Search Results
 **
 ********************/

.resultheadline {
	padding-bottom: @gutter-x2;
	border-bottom: var(--re-grey-light) .1rem solid;		

}
.searchResult {
	padding-top: @gutter-x2;
	padding-bottom: @gutter-x2;
	border-bottom: var(--re-grey-light) .1rem solid;		
}
.load-more {
	padding-top: @gutter-x2;
	padding-bottom: @gutter-x2;
}

/********************
 **
 ** Vertical Align Element
 **
 ********************/
 .valign-text,
 .valign-img {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-block-end: @gutter-x2;
}
@media screen and (min-width: @break-lg) {
	.valign-text,
 	.valign-img {
		padding-block-end: 0;
		padding-inline: @gutter-x2;
 	}
}

/****************************************
** 02. Sidebar
****************************************/

/* New */

@width-aside: 270px;
@width-aside-content: 220px;
@length-aside-icon: 35px;

#sidebar {
	display: none;
	position: fixed;
	top: 130px;
	right: @width-aside-content * -1;
	width: @width-aside;
	z-index: 1100;
	transition: all 0.5s ease-in;

	&:hover {
		right: 0;
	}

	&.hold {
		right: 0;
	}

	ul {
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: var(--re-blue-15);
		list-style-type: none;
		border-radius: 15px 0 0 15px;
		box-shadow: 10px 10px 50px #00000010;
		overflow: hidden;

		li {
			a.aside-link {
				padding-left: 10px;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				background-repeat: no-repeat;
				line-height: 1;
				transition: all 0.2s ease-in;
				color: var(--re-blue-90);

				&.active {
					color: var(--re-blue);
					svg {
						fill: var(--re-blue);
					}
				}

				&:hover {
					color: var(--re-blue);
					svg {
						fill: var(--re-blue);
					}
				}
			}
		}
	}

	.aside-icon {
		display: block;
		width: @length-aside-icon;
		height: @length-aside-icon;
		padding: 5px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--re-blue-90);
		}
	}

	.aside-name {
		display: block;
		padding: 5px 0 0 5px;
	}
}

@media screen and (min-width: @break-md) {
	#sidebar {
		display: block;
	}

}


/****************************************
** 10. Video Hero 
****************************************/

.hero-image {
	video {
		display: block;
	}
	@media screen and (min-width: @break-md) {
		video {
		}
	}
}