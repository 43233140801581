a {
    text-decoration: none;
}
blockquote {
    position: relative;
    padding: @gutter-x4;
    background-image: url(/images/quote-yellow.png);
    background-size: 50px 50px;
    background-position: 0 50px;
    background-repeat: no-repeat;
}

ul, ol, dl {
    padding-left: 15px;
}

.re-svg-icon {
    fill: #006AB2;
}
.grid-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@media screen and (max-width: @break-lg) {
    .grid-section {
        background-image: none !important;
    }
}
.p-y-6,
.py-6 {
    .padding-y-gutter(2);
}
@media screen and (min-width: @break-lg) { 
    .p-y-6,
    .py-6 {
        .padding-y-gutter(6);
    }
    
}
.mb-0 {
    margin-bottom: 0;
}

.--p-y-gutter-6 {
    .padding-y-gutter(6);
}
.--p-b-gutter-x6 {
    .padding-b-gutter(6);
}


.main-image {
    margin-bottom: @gutter-x6;
    img {
        width: 100%;
        border-radius: @radius-16;
    }
}
/*Farbschema */

.schema-white {
    background-color: var(--re-white);
}
.schema-yellow {
    background-color: var(--re-yellow);
}
.schema-blue {
    background-color: var(--re-blue);
    p, h1, h2, h3, h4, h5, h6, b, i, strong, em, li, td, span, div, blockquote, q, dt, dd {
        color: var(--re-white);
    }
    svg, path {
        fill: var(--re-white);
    }
}


/* Buttons */

.btn {
    border:none;
    background: none;
    display: inline-block;
    cursor: pointer;   
    svg {
        width: 15px;
        height: 15px;
    }
    .svg {
        height: 15px;
        width: 15px;
        line-height: 0;
    }
    border-radius: @radius;
    text-transform: uppercase;
    padding: 13px 20px 7px 20px;
    transition: all .5s ease-in-out;
    &.yellow {
        background-color: var(--re-yellow);
        color: var(--re-grey-fonts);
    }
    &.yellow-opacity-40, &:hover {
        background-color: var(--re-yellow-highlight);
        //        background-color: #ffe00040;
        color: var(--re-grey-fonts);
    }
    &.btn-transparent {
        padding: 1px;
        &:hover {
            background-color: transparent;
        }    
    }
    &.white {
        border: solid 1px var(--re-grey-light);
        color: var(--re-grey-fonts);
        background-color: var(--re-white);
        &:hover {
            background-color: var(--re-yellow-highlight);
        }
    }
    &.large {
        width: 100%;
        padding: 40px 40px;
        margin-bottom: @gutter-x2;
    }
    &.icon {
        display: inline-flex;
        justify-content: space-between;
        span {
            padding-right: @gutter;
        }
    }
    &.btn-flip-back {
        position: absolute;
        right: 0;
        top: 0;
    }
    &.btn-flip {
        position: absolute;
        bottom: @gutter;
    }
}
.close {
    width: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    svg {
        path {
            width: 100%;
            fill: var(--re-grey-fonts);
        }
    }

    &.invers {
        svg {
            path {
                fill: var(--re-white);
            }
        }
    }
}

.abs-right {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.natural-language-tools {
    .abs-right {
        right: -20px;
    }
}
.btn-icon {
    padding: 10px 20px 10px 20px;
}
.ff_re_Button {
    padding-bottom: @gutter-x2;
}
.center {
    text-align: center;
}
.article-info,
.news-info {
    margin-bottom: @gutter-x2;
}
.event-info,
.article-info,
.news-info {
    display: flex;
    * {
        color: var(--re-grey-middle);
    }
    svg {
        height: 1rem;
        margin-right: .4rem;
        margin-top: -5px;
        fill: var(--re-grey-middle);
    }
}
.info-item {
    display: flex;
    align-items: center;
    &.location {
        margin-right: @gutter;
    }
}

.ff_richtext {
    margin-bottom: @gutter-x2;
}

/* Farbklassen */

.yellow {
    color: var(--re-yellow);
}
.color-blue {
    color: var(--re-blue);
}
/* iframe (embed) */

.media-embed-ratio-16-9,
.media-embed-ratio-16-9 iframe {
    aspect-ratio: 16 / 9;
}

/* Details (Akkordion) */

.akkordion::part(base)  {
    background-color: transparent;
}
/* Image */

.ff-image {
    border-radius: @radius-16;
    margin-bottom: @gutter-x2;
}
.width-100 {
    width: 100%;
}
.ff_iframe {
    display: flex;
    justify-content: center;
    iframe {
        width: 60vw;
        height: auto;
        aspect-ratio: 16/9;
    }
}
.scroll-area {
    height: 100%;
    overflow-y: auto; 
}
.line {
    margin-bottom: 42px;
    border-bottom: 1px solid var(--re-grey-light);
    width: 100%;
}

.col-flex {
	display: flex;
}
.flex-space-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.margin-bottom-li {
	li {
		margin-bottom: @gutter-x2;
	}
}
