main {
    .grid-section { 
        margin-bottom: @gutter-x2;
    }
    @media screen and (min-width: @break-lg) {
        .grid-section { 
            margin-bottom: @gutter-x6;
        }
        .indent {
            > .container {
                padding-left: @gutter-x6;
                padding-right: @gutter-x6;
            }
        }
    }
    .prefooter {
        margin-bottom: 0;;
    }
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(1);
    row-gap: 64px;
}

@media (min-width: 768px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 64px;
    }
}

@media (min-width: 992px) {
    .footer-grid {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 64px;
    }
}

@media (min-width: 1200px) {
    .footer-grid {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 64px;
    }
}