
.slideDown {
	animation-name: slideDown;
	animation-duration: 1s;	
	animation-timing-function: ease;	
	visibility: visible;
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(-2%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

.slideUp {
	animation-name: slideUp;
	animation-duration: 1s;	
	animation-timing-function: ease;	
	visibility: visible;			
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
	50%{
		transform: translateY(-8%);
	}
	65%{
		transform: translateY(4%);
	}
	80%{
		transform: translateY(-4%);
	}
	95%{
		transform: translateY(2%);
	}			
	100% {
		transform: translateY(0%);
	}	
}

.slideLeft {
	animation-name: slideLeft;
	animation-duration: 0.3s;	
	animation-timing-function: ease-out;	
	visibility: visible;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(100%);
		opacity: 0.0;		
	}
	100% {
		transform: translateX(0%);
		opacity: 1.0;		
	}
}


.slideRight {
	animation-name: slideRight;
	animation-duration: 1s;	
	animation-timing-function: ease-in-out;	
	visibility: visible;	
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%);
		opacity: 0.0;		
	}
	100% {
		transform: translateX(0%);
		opacity: 1.0;		
	}	
}

.slideExpandUp {
	animation-name: slideExpandUp;
	animation-duration: 1.6s;	
	animation-timing-function: ease-out;	
	visibility: visible;	
}

@keyframes slideExpandUp {
	0% {
		transform: translateY(100%) scaleX(0);
		opacity: 0.0;		
	}
	100%{
		transform: translateY(0%) scaleX(1);		
		opacity: 1.0;		
	}
}

.fadeIn {
	animation-name: fadeIn;
	animation-duration: 1.5s;	
	animation-timing-function: ease-in-out;	
	visibility: visible;	
}

@keyframes fadeIn {
	0% {
		opacity: 0;		
	}
	100% {
		opacity: 1;	
	}		
}


.komplex {
	animation-name: komplex;
	animation-duration: 1.6s;	
	animation-timing-function: ease-out;	
	visibility: visible;			
}

@keyframes komplex {
	0% {
		transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}		
}


.stretchLeft {
	animation-name: stretchLeft;
	animation-duration: 1.5s;	
	animation-timing-function: ease-out;	
	transform-origin: 100% 0%;
	visibility: visible;
}

@keyframes stretchLeft {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}

.stretchRight {
	animation-name: stretchRight;
	animation-duration: 1.5s;	
	animation-timing-function: ease-out;	
	transform-origin: 0% 0%;
	visibility: visible;

}

@keyframes stretchRight {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}