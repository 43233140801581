
/****************************************
** 01. Navigationen
****************************************/

/*Header */


.ff-navbar-nav {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.subnav-right {
	position: relative;
	background-color: var(--re-grey-light);
	border-bottom-right-radius: @radius-20;
	padding: @gutter;
	display: flex;
	flex-direction: column;
	gap: @gutter;
	.header-teaser {
		display: flex;
		&:hover {
			.header-teaser-image {
				.hoverlay {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	.header-teaser-image {
		position: relative;
		border-radius: @radius;
		overflow: hidden;
		width: 96px;
		height: 96px;
		img {
			width: 100%;
			height: 100%;
		}
		.hoverlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			visibility: hidden;
			opacity: 0;
			background-color: var(--re-blue-90);
			transition: all 0.25s ease-in-out;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--re-white);			
			svg {
				fill: var(--re-white);
			}
		}
	}
	.header-teaser-text {
		max-width: 70%;
		padding-left: @gutter-x2;
		padding-right: @gutter;
		padding-top: 9.6px;
	}
}





.subnav-linkliste,
.subnav-linkliste-all {
	display: flex;
	a {
		text-transform: uppercase;
		&:hover {
			+ svg {
				fill: var(--re-blue);
			}
		}
	}
	&::before {
		content: '';
		width: 100%;
		border-bottom: var(--re-grey-light) solid 1px;
		position: absolute;
		top: 0;
	}
	.headerlink {
		width: 100%;
	}
	&::before {
		border-bottom: var(--re-grey-between) solid 1px;
	}
}


.headerlink {
	display: flex;
	align-items: center;
	a {
		padding-right: @gutter;
		font-family: var(--re-font-univers-bold);
		font-weight: 400;
	}
	path, use {
		fill: var(--re-blue);
	}
	svg {
		margin-top: -5px;
	}
}

.nav-col {
	list-style-type: none;
	padding: 20px;
}
.col-headline {
	font-family: var(--re-font-univers-bold);
	padding-bottom: 0.8rem;
}
.nav-item {
	&.current {
		> a {
			color: #006AB2;
		}
	}
	a {
		color: #3E3E40;
		&:hover{
			color: #006AB2;
		} 
	}
	.nav-item {
		padding-bottom: 0.8rem;
	}
}

.menu-tools {
	display: flex;
}
@media (max-width: 759px) {


	/* Hamburger */
	.menu-tools {
		align-items: center;
		--button-color: var(--re-blue);

		.mobile-menu {
			margin-left: 12px;
			width: 30px;
			height: 30px;

			.open-mobile-menu {
				width: 100%;
				height: 100%;
				padding: 0;
				background: transparent;
				border: none;

				svg {
					width: 20px;
					height: 20px;
					fill: var(--button-color);
				}

				.line {
					transition: transform 300ms ease-in 300ms, rotate 300ms ease-in, opacity 0ms 300ms;
					transform-origin: center;
					transform: translateY(0);
				}

				&[aria-expanded="true"] .line {
					transition: transform 300ms ease-in, rotate 300ms ease-in 300ms, opacity 0ms 300ms;
				}

				&[aria-expanded="true"] .top {
					transform: translateY(18px);
				}

				&[aria-expanded="true"] .bottom {
					transform: translateY(-22px);
				}

				&[aria-expanded="true"] .top {
					rotate: 45deg;
				}

				&[aria-expanded="true"] .middle {
					opacity: 0;
				}

				&[aria-expanded="true"] .bottom {
					rotate: -45deg;
				}
			}
		}
	}


	.ff-navbar {
		position: sticky;
		top:0;
		z-index: 99;
		width: 100%;
		background-color: var(--re-white);
		border-bottom: 1px var(--re-grey-between) solid;
	}
	.navbar-wrapper {
		display: flex;
    	justify-content: space-between;
    	align-items: center;
		padding-top: 12px;
		padding-bottom: 12px;
	}
	.logo {
		width: 150px;
	}
	.main-nav-wrapper {
		display: none;
		position: absolute;
		background: #fff;
		z-index: 100;
		width: 100%;
		height: 100vh;
		top: 62px;
		left: 0;
		&.visible {
			display: block;
		}
	}

	.ff-navbar-nav {
		padding: 0 @gutter @gutter @gutter;
		> li.nav-item {
			display: flex;
			justify-content: space-between;
			padding: @gutter 0 @gutter 0;
			border-bottom: var(--re-grey-light) 1px solid;
			svg {
				fill: var(--re-blue);
			}
		}
		> li.has-children {

		}
	}
	.subnav-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		transition: all .5s;
		display: block;
		transform: translateX(100vw);
		overflow-y: auto;
		&.visible {
			transform: translateX(0);
		}
	}
	.headerlink {
		padding-top: @gutter;
		display: flex;
		justify-content: space-between;
		border-top: var(--re-grey-light) solid 1px;
	}

	.subnav-linkliste {
		position: relative;
		flex-direction: column;
		&::before {
			display: none;
		}		
		.headerlink {
			padding: @gutter;
		}
	}
	.subnav-linkliste-all {
		position: relative;
		&::before {
			width: 100%;
		}
	}
	/* Hamburger */
	.menu-tools {
		display: flex;
		.mobile-menu {
			margin-left: 12px;
		}
	}

	/* Mobile Header im (Sub)Menu*/
	.mobile-tools {
		position: relative;
		display: flex;
		justify-content: space-between;
		border-bottom: var(--re-grey-light) solid 1px;
		padding: 8px 0;;
		svg {
			fill: var(--re-blue);
		}
		.btn.close {
			svg {
				path {
					fill: var(--re-blue);
				}
		
			}
		}
	}
	.mobile-lang-switcher {
		display: flex;
		align-items: center;
		padding: @gutter 0 10px @gutter;
		
		.lang-selected {
			 color: var(--re-blue);
		}
		a {
			&:hover {
				color: var(--re-blue);
			}
		}
	}

	.subnav-titel {
		line-height: 1;
		display: flex;
		align-items: center;
	}
	.subnav-right {
		border-radius: 0;
	}	
	.mobile-arrow {
		display: block;
	}
	.btn-placeholder {
		width: 55px;
	}
}


@media (min-width: 760px) {
	.subnav-tools {
		display: none;
	}
	.ff-navbar {
		padding: 20px;
		position: sticky;
		top:0;
		z-index: 100;
		width: 100%;
		height: 100px;
		display: flex;
		background-color: var(--re-white);
		border-bottom: 1px var(--re-grey-between) solid;
	}
	@nav-position: 50px;
	.subnav-container {
		display: none;
		position: absolute;
		left: 0;
		top: 45px;
		background-color: var(--re-white);
		width: 100%;
		box-shadow: 0px 20px 20px #00000010;
		max-height:0;
		margin-top: 35px;
		opacity: 0;
		text-align: left;
		border-radius: 0 0 @radius-20 @radius-20;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: @nav-position;
			position: absolute;
			top: @nav-position * -1;
		}
	}
	.subnav-navigation {
		display: flex;
		flex-wrap: wrap;
	}

	.subnav-left {
		width: 66.67%;
		position: relative;
		padding-bottom: 80px;
		background-color: var(--re-white);
		border-bottom-left-radius: @radius;
	}

	.subnav-right {
		width: 33.34%;
		padding-bottom: 80px;
	}
	.subnav-linkliste-all,
	.subnav-linkliste {
		display: flex;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		a {
			text-transform: uppercase;
			&:hover {
				+ svg {
					fill: var(--re-blue);
				}
			}
		}
		&::before {
			content: '';
			width: calc(100% - @gutter-x2);
			margin-left: @gutter;
			margin-right: @gutter;
		}
	}

	.headerlink {
		width: 33.3%;
		padding: @gutter;
		white-space: nowrap;
	}
	.navbar-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
	}
	.logo {
		width: 20%;
	}
	.main-nav-wrapper {
		width: 80%;
		display: flex;
		justify-content: right;
		align-items: center;
	}
	
	.nav-col {
		max-width: 50%;
	}

	.ff-navbar-nav {
		text-align: right;
		> li.nav-item {
			display: inline-block;
			text-align: right;
			margin-left: @gutter;
			a.mobile-arrow {
				display: none;
			}
			> a {
				display: block;
			}
			&:hover {
				.subnav-container {
					display: flex;
					opacity: 1;
					max-height: 700px;
					animation: fadein .5s ease-in-out;
				}
			}
		}
	}

	@keyframes fadein {
		from	{ max-height: 0; opacity: 0; }
		to		{ max-height: 500px; opacity: 1; }
	}

	.mobile-menu,
	.mobile-tools {
		display: none;
	}
}

/*

animiertes SVG


<button class="menu-toggle" id="menu-toggle" aria-expanded="false"><span class="screen-reader-text">Menu</span>
									<svg class="icon icon-menu-toggle" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100">
										<g class="svg-menu-toggle">
											<path class="line line-1" d="M5 13h90v14H5z"/>
											<path class="line line-2" d="M5 43h90v14H5z"/>
											<path class="line line-3" d="M5 73h90v14H5z"/>
										</g>
									</svg>
							</button>


body {
  background-color: #fafafa;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;
}

button,
input[type="button"] {
	border: none;
	border-radius: 0;
	background: #000;
	color: #fff;
  cursor: pointer;
	display: inline-block;
	padding: 1em 1.5em;
	text-transform: uppercase;
	transition: all 0.25s ease-in-out;
}

.menu-toggle {
  font-size: 0.875em;
	padding: 1em;
}
.menu-toggle:focus {
	outline: thin dotted;
  outline-offset: -2px;
}

.icon {
	display: inline-block;
	fill: currentColor;
	height: 1em;
	width: 1em;
	vertical-align: middle;
	position: relative; /* Align more nicely with capital letters
	top: -0.0625em;
}
*/
/* Menu toggle styles. */

.icon-menu-toggle {
	width: 2em;
	height: 2em;
  top: 0;
}

/* Animate menu icon (lines). */
.svg-menu-toggle .line {
  opacity: 1;
  transform: rotate(0) translateY(0) translateX(0);
  transform-origin: 1em 1em;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
}
.svg-menu-toggle .line-1 {
  transform-origin: 1em 2.5em;
}
.svg-menu-toggle .line-3 {
  transform-origin: 1em 4.5em;
}

.menu-toggle.opened .svg-menu-toggle .line-1 {
  transform: rotate(45deg) translateY(0) translateX(0);
}
.menu-toggle.opened .svg-menu-toggle .line-2 {
  opacity: 0;
}
.menu-toggle.opened .svg-menu-toggle .line-3 {
  transform: rotate(-45deg) translateY(0em) translateX(0em);
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}


/****************************************
** 02. Toolbar
****************************************/

.bottom-line {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	padding-bottom: 16px;
	.lang-switcher {
		display: none;
	}
	.legal {
		display: inline-block;
		text-align: center;
		width: 100%;
	}
	@media (min-width: @break-md) {
		.lang-switcher {
			display:flex;
			justify-content: center;
			padding: @gutter-x2;
		}
	}
	@media (min-width: @break-lg) {
		.lang-switcher {
			display: inline-block;
			margin-right: @gutter;
			padding: 0;
		}
		.legal {
			text-align: left;
			width: auto;
		}
	}
	a {
		color: var(--re-white);
		&:hover {
			color: var(--re-yellow);
		}
	}
	.lang-selected {
		color: var(--re-yellow);
   }
}

#lang-switch-tool-top {
	display: none;
}
@media (min-width: @break-md) {
	#lang-switch-tool-top {
		display: inline-block;
		&.lang-switcher {
			white-space: nowrap;
			padding-left: 16px;
		}
		a {
			&:hover {
				color: var(--re-blue);
			}
		}
		.lang-selected {
			color: var(--re-blue);
	   }		
	}
}

ul.toolbar {
    padding:0;
	margin:0;
	margin-bottom: @gutter;
	display: flex;
	justify-content: center;
	column-gap: 16px;
	@media (min-width: 992px) {
		justify-content: right;
		margin-bottom: 0;
	}
	li {
		list-style:none;
		display:inline-block;
		&:hover {
			path {
				fill:#FFE000;
			}
		}

	}
	path {
		fill:#fff;
	}
}


/****************************************
** 02. Metanavigation
****************************************/


div.metanavi {
	ul {
	    list-style: none;
		
	}
}

/****************************************
** 04. Suche
****************************************/

.search-overlay {
	position: absolute;
	visibility: hidden;
	right: -12px;
	top: -19px;
	opacity: 0;
	width: 0;
	height: 0;
	transition: all .4s ease-in-out;
	overflow: hidden;
}
.searchbar-suggestionList {
	display: none;
	flex-direction: column;
}
.search-container {
	position: relative;
	top: -2px;
	margin-left: @gutter;
	@media (max-width: 759px) {
		&:hover {
			.search-overlay {
				top: -16px;
				overflow: visible;
				visibility: visible;
				opacity: 1;
				width: auto;
				height: auto;
				input {
					width: 70vw;
					padding-top: 8px;
				}
			}
			.searchbar-suggestionList {
				display: flex;
			}
		}
	}
	@media (min-width: 760px) {
		&.active {
			.search-overlay {
				overflow: visible;
				visibility: visible;
				opacity: 1;
				width: auto;
				height: auto;
				input {
					width: 82vw;
					padding-right: 2rem;
				}
			}
			.searchbar-suggestionList {
				display: flex;
			}
		}
	}
	@media (min-width: 820px) {
		&.active {
			.search-overlay {
				input {
					width: 83vw;
				}
			}
		}
	}
	@media (min-width: 992px) {
		&.active {
			.search-overlay {
				input {
					width: 36rem;
				}
			}
		}
	}
}

.search-input {
    background-color:var(--re-grey-light);
    border-radius: 10px;
    display: flex;
    padding: 8px 12px 6px 12px;
    input {
        width: 1rem;
        border: none;
        background: transparent;
        transition: all .4s ease-in-out;
    }
	.btn.btn-transparent {
		padding: 12px 1px 8px 1px;
	}
}
.input-autocomplete {
	position: relative;
	display: flex;
	input {
		line-height: 1.6;
	}
	ul {
		position: absolute;
		top: 32px;
		width: 100%;
		margin: 0;
		padding: @gutter;
		list-style: none;
		background-color: var(--re-white);
		box-shadow: 0px 20px 20px #00000010;
		border-bottom-left-radius: @radius;
		border-bottom-right-radius: @radius;
		li {
			cursor: pointer;
			&:hover {
				color: var(--re-blue);
			}
			span.fit{
				font-weight: bold;
			}
		}
	}
}

