/********************
 **
 ** Buttons
 **
 ********************/


div.action-button{
    margin-top:15px;
    margin-bottom:15px;
    box-sizing: border-box
}
a.action:link{text-decoration:none;}
a.action-sm{
	line-height: 25px;
	font-size: 16px;
	padding: 5px 13px 5px 13px;
}
a.action-md{
	line-height: 38px;
	font-size: 25px;
	padding: 8px 20px 8px 20px;
}
a.action-lg{
	line-height: 60px;
	font-size: 40px;
	padding: 10px 40px 10px 40px;
}
