html, body {
//	height: 100%;
}
/*
html {
	font-size: 31.2%;
	@media (min-width: 768px) {
		font-size: 62.5%;
	}
	height: 100%;
	position: relative;
	scroll-behavior: smooth;
}
*/

html {
	scroll-behavior: smooth;
}

body {
	background-attachment: fixed !important;
    background-size: cover !important;
    position: relative;
	min-height: 100%;
	margin: 0;
	font-family: var(--re-body-font-family);
	font-size: var(--font-size);
	font-weight: 300;
	line-height: 1.2;
	color: var(--bs-body-color);
	background-color: var(--bs-body-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&.navbar-open {
		overflow: hidden;
	}
}

body::after {
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: -1;   
	background-attachment: fixed !important;
	background-size: cover !important;
}

a:active,a:focus {
	outline: none;
}