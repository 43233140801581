@charset "UTF-8";
/*<link rel="preload" as="font">*/
@font-face {
  /*Univers 55, 55 Roman*/
  font-family: "Univers LT Std";
  src: url("/fonts/UniversPro55Roman.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  /*Univers 57 Condensed, Regular*/
  font-family: "Univers 57 Condensed";
  src: url("/fonts/UniversPro57Condensed.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  /*Univers LT Std, 65 Bold*/
  font-family: "Univers LT Std Bold";
  src: url("/fonts/UniversPro65Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  /*Univers LT Std, 55 Oblique*/
  font-family: "Univers LT Std Oblique";
  src: url("/fonts/UniversPro55Oblique.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
h1 {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  text-transform: uppercase;
  color: var(--re-blue);
  line-height: 1;
  margin-bottom: 32px;
  hyphens: auto;
  -webkit-hyphens: auto;
}
h2 {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  text-transform: uppercase;
  color: var(--re-blue);
  line-height: 1;
  margin-bottom: 16px;
  hyphens: auto;
  -webkit-hyphens: auto;
}
h3 {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-6);
  text-transform: uppercase;
  color: var(--re-blue);
  line-height: 1.1;
  margin-bottom: 16px;
  hyphens: auto;
  -webkit-hyphens: auto;
}
h4 {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-2);
  color: var(--re-grey-fonts);
  line-height: 1.1;
  margin-bottom: 16px;
  hyphens: auto;
  -webkit-hyphens: auto;
}
h5 {
  font-size: var(--font-size-2);
  color: var(--re-grey-fonts);
  margin-bottom: 16px;
  text-transform: uppercase;
  hyphens: auto;
  -webkit-hyphens: auto;
}
h6 {
  font-size: var(--font-size-1);
  color: var(--re-grey-fonts);
  margin-bottom: 16px;
  text-transform: uppercase;
}
p {
  font-family: var(--re-font-univers);
  font-size: var(--font-size);
  line-height: var(--re-line-height);
  font-weight: var(--re-font-weight-normal);
  color: var(--re-grey-fonts);
  margin-bottom: 16px;
}
p a {
  font-family: inherit;
  font-size: inherit;
}
p strong {
  font-family: var(--re-font-univers-bold);
  font-size: inherit;
  font-weight: 400;
}
p i {
  font-family: inherit;
  font-size: inherit;
}
i,
em {
  font-family: var(--re-font-univers-oblique);
  color: inherit;
}
strong {
  font-family: var(--re-font-univers-bold);
  color: inherit;
}
li {
  font-family: var(--re-font-univers);
  font-size: var(--font-size);
  line-height: var(--re-line-height);
  color: var(--re-grey-fonts);
}
/****************************************
** TOC Variables
*****************************************
** 02. Pfad
** 04. Opacity
** 06. Bootstrap Override

****************************************/
/****************************************
** 04. Opacity
****************************************/
/****************************************
** 05. Fonts
****************************************/
/****************************************
** 06. Breakpoints
****************************************/
/****************************************
** 06. Basic Vars
****************************************/
/****************************************
** 08. Show/Hide
****************************************/
/****************************************
** 08. Gutter
****************************************/
:root {
  --re-white: #ffffff;
  --re-white-80: #ffffff80;
  --re-blue: #006AB2;
  --re-blue-90: #006ab290;
  --re-blue-15: #d9e9f4;
  --re-yellow: #FFE000;
  --re-yellow-highlight: #fff7bf;
  --re-grey-light: #eaeaea;
  --re-grey-between: #d3d3d3;
  --re-grey-middle: #b2b2b2;
  --re-grey-dark: #9c9c9c;
  --re-grey-fonts: #3e3e40;
  --re-font-univers: "Univers LT Std";
  --re-font-univers-bold: "Univers LT Std Bold";
  --re-font-univers-condensed: "Univers 57 Condensed";
  --re-font-univers-oblique: "Univers LT Std Oblique";
  --re-font-weight-normal: 300;
  --re-line-height: 1.6;
  --re-body-font-family: var(--re-font-univers);
  --re-body-font-size: var(--font-size);
  --re-body-font-weight: var(--re-font-weight-normal);
  --re-body-line-height: var(--re-line-height);
  --re-body-color: var(--re-grey-fonts);
  --re-body-bg: var(--re-white);
  --re-heading-color: inherit;
  --re-link-color: var(--re-blue);
  --re-link-decoration: none;
  --re-link-decoration-hover: underline;
  --re-link-hover-color: var(--re-blue);
  --re-border-radius: 10px;
  --re-border-radius-16: 16px;
  --re-border-radius-20: 20px;
  --re-border-radius-sm: 0.25rem;
  --re-border-radius-lg: 0.5rem;
  --re-border-radius-xl: 1rem;
  --re-border-radius-xxl: 2rem;
  --re-border-radius-2xl: var(--bs-border-radius-xxl);
  --re-form-valid-color: #198754;
  --re-form-valid-border-color: #198754;
  --re-form-invalid-color: #dc3545;
  --re-form-invalid-border-color: #dc3545;
  --font-size-small: 13px;
  --font-size: clamp(0.813rem, 1vw + 1rem, 1rem);
  --font-size-1: clamp(0.938rem, 1vw + 1rem, 1.188rem);
  --font-size-2: clamp(1rem, 1vw + 1rem, 1.438rem);
  --font-size-3: clamp(1.375rem, 1vw + 1.25rem, 1.875rem);
  --font-size-4: clamp(1.375rem, 1vw + 1rem, 2.063rem);
  --font-size-5: clamp(1.25rem, 1vw + 1.75rem, 3.125rem);
  --font-size-6: clamp(1.188rem, 1vw + 1.75rem, 3.625rem);
  --font-size-7: clamp(6.563rem, 1vw + 3rem, 7.5rem);
}
/****************************************
** TOC
*****************************************
** 01. Col overwrite

****************************************/
/****************************************
** 01. Col overwrite
***************************************/
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: static;
}
/*
html {
	font-size: 31.2%;
	@media (min-width: 768px) {
		font-size: 62.5%;
	}
	height: 100%;
	position: relative;
	scroll-behavior: smooth;
}
*/
html {
  scroll-behavior: smooth;
}
body {
  background-attachment: fixed !important;
  background-size: cover !important;
  position: relative;
  min-height: 100%;
  margin: 0;
  font-family: var(--re-body-font-family);
  font-size: var(--font-size);
  font-weight: 300;
  line-height: 1.2;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body.navbar-open {
  overflow: hidden;
}
body::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-attachment: fixed !important;
  background-size: cover !important;
}
a:active,
a:focus {
  outline: none;
}
a {
  text-decoration: none;
}
blockquote {
  position: relative;
  padding: 64px;
  background-image: url(/images/quote-yellow.png);
  background-size: 50px 50px;
  background-position: 0 50px;
  background-repeat: no-repeat;
}
ul,
ol,
dl {
  padding-left: 15px;
}
.re-svg-icon {
  fill: #006AB2;
}
.grid-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 992px) {
  .grid-section {
    background-image: none !important;
  }
}
.p-y-6,
.py-6 {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (min-width: 992px) {
  .p-y-6,
  .py-6 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
.mb-0 {
  margin-bottom: 0;
}
.--p-y-gutter-6 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.--p-b-gutter-x6 {
  padding-bottom: 96px;
}
.main-image {
  margin-bottom: 96px;
}
.main-image img {
  width: 100%;
  border-radius: 16px;
}
/*Farbschema */
.schema-white {
  background-color: var(--re-white);
}
.schema-yellow {
  background-color: var(--re-yellow);
}
.schema-blue {
  background-color: var(--re-blue);
}
.schema-blue p,
.schema-blue h1,
.schema-blue h2,
.schema-blue h3,
.schema-blue h4,
.schema-blue h5,
.schema-blue h6,
.schema-blue b,
.schema-blue i,
.schema-blue strong,
.schema-blue em,
.schema-blue li,
.schema-blue td,
.schema-blue span,
.schema-blue div,
.schema-blue blockquote,
.schema-blue q,
.schema-blue dt,
.schema-blue dd {
  color: var(--re-white);
}
.schema-blue svg,
.schema-blue path {
  fill: var(--re-white);
}
/* Buttons */
.btn {
  border: none;
  background: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 13px 20px 7px 20px;
  transition: all 0.5s ease-in-out;
}
.btn svg {
  width: 15px;
  height: 15px;
}
.btn .svg {
  height: 15px;
  width: 15px;
  line-height: 0;
}
.btn.yellow {
  background-color: var(--re-yellow);
  color: var(--re-grey-fonts);
}
.btn.yellow-opacity-40,
.btn:hover {
  background-color: var(--re-yellow-highlight);
  color: var(--re-grey-fonts);
}
.btn.btn-transparent {
  padding: 1px;
}
.btn.btn-transparent:hover {
  background-color: transparent;
}
.btn.white {
  border: solid 1px var(--re-grey-light);
  color: var(--re-grey-fonts);
  background-color: var(--re-white);
}
.btn.white:hover {
  background-color: var(--re-yellow-highlight);
}
.btn.large {
  width: 100%;
  padding: 40px 40px;
  margin-bottom: 32px;
}
.btn.icon {
  display: inline-flex;
  justify-content: space-between;
}
.btn.icon span {
  padding-right: 16px;
}
.btn.btn-flip-back {
  position: absolute;
  right: 0;
  top: 0;
}
.btn.btn-flip {
  position: absolute;
  bottom: 16px;
}
.close {
  width: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.close svg path {
  width: 100%;
  fill: var(--re-grey-fonts);
}
.close.invers svg path {
  fill: var(--re-white);
}
.abs-right {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.natural-language-tools .abs-right {
  right: -20px;
}
.btn-icon {
  padding: 10px 20px 10px 20px;
}
.ff_re_Button {
  padding-bottom: 32px;
}
.center {
  text-align: center;
}
.article-info,
.news-info {
  margin-bottom: 32px;
}
.event-info,
.article-info,
.news-info {
  display: flex;
}
.event-info *,
.article-info *,
.news-info * {
  color: var(--re-grey-middle);
}
.event-info svg,
.article-info svg,
.news-info svg {
  height: 1rem;
  margin-right: 0.4rem;
  margin-top: -5px;
  fill: var(--re-grey-middle);
}
.info-item {
  display: flex;
  align-items: center;
}
.info-item.location {
  margin-right: 16px;
}
.ff_richtext {
  margin-bottom: 32px;
}
/* Farbklassen */
.yellow {
  color: var(--re-yellow);
}
.color-blue {
  color: var(--re-blue);
}
/* iframe (embed) */
.media-embed-ratio-16-9,
.media-embed-ratio-16-9 iframe {
  aspect-ratio: 16 / 9;
}
/* Details (Akkordion) */
.akkordion::part(base) {
  background-color: transparent;
}
/* Image */
.ff-image {
  border-radius: 16px;
  margin-bottom: 32px;
}
.width-100 {
  width: 100%;
}
.ff_iframe {
  display: flex;
  justify-content: center;
}
.ff_iframe iframe {
  width: 60vw;
  height: auto;
  aspect-ratio: 16/9;
}
.scroll-area {
  height: 100%;
  overflow-y: auto;
}
.line {
  margin-bottom: 42px;
  border-bottom: 1px solid var(--re-grey-light);
  width: 100%;
}
.col-flex {
  display: flex;
}
.flex-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.margin-bottom-li li {
  margin-bottom: 32px;
}
main .grid-section {
  margin-bottom: 32px;
}
@media screen and (min-width: 992px) {
  main .grid-section {
    margin-bottom: 96px;
  }
  main .indent > .container {
    padding-left: 96px;
    padding-right: 96px;
  }
}
main .prefooter {
  margin-bottom: 0;
}
.footer-grid {
  display: grid;
  grid-template-columns: repeat(1);
  row-gap: 64px;
}
@media (min-width: 768px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 64px;
  }
}
@media (min-width: 992px) {
  .footer-grid {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 64px;
  }
}
@media (min-width: 1200px) {
  .footer-grid {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 64px;
  }
}
/****************************************
** 01. Navigationen
****************************************/
/*Header */
.ff-navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.subnav-right {
  position: relative;
  background-color: var(--re-grey-light);
  border-bottom-right-radius: 20px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.subnav-right .header-teaser {
  display: flex;
}
.subnav-right .header-teaser:hover .header-teaser-image .hoverlay {
  visibility: visible;
  opacity: 1;
}
.subnav-right .header-teaser-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 96px;
  height: 96px;
}
.subnav-right .header-teaser-image img {
  width: 100%;
  height: 100%;
}
.subnav-right .header-teaser-image .hoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: var(--re-blue-90);
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--re-white);
}
.subnav-right .header-teaser-image .hoverlay svg {
  fill: var(--re-white);
}
.subnav-right .header-teaser-text {
  max-width: 70%;
  padding-left: 32px;
  padding-right: 16px;
  padding-top: 9.6px;
}
.subnav-linkliste,
.subnav-linkliste-all {
  display: flex;
}
.subnav-linkliste a,
.subnav-linkliste-all a {
  text-transform: uppercase;
}
.subnav-linkliste a:hover + svg,
.subnav-linkliste-all a:hover + svg {
  fill: var(--re-blue);
}
.subnav-linkliste::before,
.subnav-linkliste-all::before {
  content: '';
  width: 100%;
  border-bottom: var(--re-grey-light) solid 1px;
  position: absolute;
  top: 0;
}
.subnav-linkliste .headerlink,
.subnav-linkliste-all .headerlink {
  width: 100%;
}
.subnav-linkliste::before,
.subnav-linkliste-all::before {
  border-bottom: var(--re-grey-between) solid 1px;
}
.headerlink {
  display: flex;
  align-items: center;
}
.headerlink a {
  padding-right: 16px;
  font-family: var(--re-font-univers-bold);
  font-weight: 400;
}
.headerlink path,
.headerlink use {
  fill: var(--re-blue);
}
.headerlink svg {
  margin-top: -5px;
}
.nav-col {
  list-style-type: none;
  padding: 20px;
}
.col-headline {
  font-family: var(--re-font-univers-bold);
  padding-bottom: 0.8rem;
}
.nav-item.current > a {
  color: #006AB2;
}
.nav-item a {
  color: #3E3E40;
}
.nav-item a:hover {
  color: #006AB2;
}
.nav-item .nav-item {
  padding-bottom: 0.8rem;
}
.menu-tools {
  display: flex;
}
@media (max-width: 759px) {
  /* Hamburger */
  .menu-tools {
    align-items: center;
    --button-color: var(--re-blue);
  }
  .menu-tools .mobile-menu {
    margin-left: 12px;
    width: 30px;
    height: 30px;
  }
  .menu-tools .mobile-menu .open-mobile-menu {
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
    border: none;
  }
  .menu-tools .mobile-menu .open-mobile-menu svg {
    width: 20px;
    height: 20px;
    fill: var(--button-color);
  }
  .menu-tools .mobile-menu .open-mobile-menu .line {
    transition: transform 300ms ease-in 300ms, rotate 300ms ease-in, opacity 0ms 300ms;
    transform-origin: center;
    transform: translateY(0);
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .line {
    transition: transform 300ms ease-in, rotate 300ms ease-in 300ms, opacity 0ms 300ms;
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .top {
    transform: translateY(18px);
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .bottom {
    transform: translateY(-22px);
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .top {
    rotate: 45deg;
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .middle {
    opacity: 0;
  }
  .menu-tools .mobile-menu .open-mobile-menu[aria-expanded="true"] .bottom {
    rotate: -45deg;
  }
  .ff-navbar {
    position: sticky;
    top: 0;
    z-index: 99;
    width: 100%;
    background-color: var(--re-white);
    border-bottom: 1px var(--re-grey-between) solid;
  }
  .navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .logo {
    width: 150px;
  }
  .main-nav-wrapper {
    display: none;
    position: absolute;
    background: #fff;
    z-index: 100;
    width: 100%;
    height: 100vh;
    top: 62px;
    left: 0;
  }
  .main-nav-wrapper.visible {
    display: block;
  }
  .ff-navbar-nav {
    padding: 0 16px 16px 16px;
  }
  .ff-navbar-nav > li.nav-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 16px 0;
    border-bottom: var(--re-grey-light) 1px solid;
  }
  .ff-navbar-nav > li.nav-item svg {
    fill: var(--re-blue);
  }
  .subnav-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transition: all 0.5s;
    display: block;
    transform: translateX(100vw);
    overflow-y: auto;
  }
  .subnav-container.visible {
    transform: translateX(0);
  }
  .headerlink {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    border-top: var(--re-grey-light) solid 1px;
  }
  .subnav-linkliste {
    position: relative;
    flex-direction: column;
  }
  .subnav-linkliste::before {
    display: none;
  }
  .subnav-linkliste .headerlink {
    padding: 16px;
  }
  .subnav-linkliste-all {
    position: relative;
  }
  .subnav-linkliste-all::before {
    width: 100%;
  }
  /* Hamburger */
  .menu-tools {
    display: flex;
  }
  .menu-tools .mobile-menu {
    margin-left: 12px;
  }
  /* Mobile Header im (Sub)Menu*/
  .mobile-tools {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: var(--re-grey-light) solid 1px;
    padding: 8px 0;
  }
  .mobile-tools svg {
    fill: var(--re-blue);
  }
  .mobile-tools .btn.close svg path {
    fill: var(--re-blue);
  }
  .mobile-lang-switcher {
    display: flex;
    align-items: center;
    padding: 16px 0 10px 16px;
  }
  .mobile-lang-switcher .lang-selected {
    color: var(--re-blue);
  }
  .mobile-lang-switcher a:hover {
    color: var(--re-blue);
  }
  .subnav-titel {
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .subnav-right {
    border-radius: 0;
  }
  .mobile-arrow {
    display: block;
  }
  .btn-placeholder {
    width: 55px;
  }
}
@media (min-width: 760px) {
  .subnav-tools {
    display: none;
  }
  .ff-navbar {
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100px;
    display: flex;
    background-color: var(--re-white);
    border-bottom: 1px var(--re-grey-between) solid;
  }
  .subnav-container {
    display: none;
    position: absolute;
    left: 0;
    top: 45px;
    background-color: var(--re-white);
    width: 100%;
    box-shadow: 0px 20px 20px #00000010;
    max-height: 0;
    margin-top: 35px;
    opacity: 0;
    text-align: left;
    border-radius: 0 0 20px 20px;
  }
  .subnav-container::before {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    top: -50px;
  }
  .subnav-navigation {
    display: flex;
    flex-wrap: wrap;
  }
  .subnav-left {
    width: 66.67%;
    position: relative;
    padding-bottom: 80px;
    background-color: var(--re-white);
    border-bottom-left-radius: 10px;
  }
  .subnav-right {
    width: 33.34%;
    padding-bottom: 80px;
  }
  .subnav-linkliste-all,
  .subnav-linkliste {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .subnav-linkliste-all a,
  .subnav-linkliste a {
    text-transform: uppercase;
  }
  .subnav-linkliste-all a:hover + svg,
  .subnav-linkliste a:hover + svg {
    fill: var(--re-blue);
  }
  .subnav-linkliste-all::before,
  .subnav-linkliste::before {
    content: '';
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }
  .headerlink {
    width: 33.3%;
    padding: 16px;
    white-space: nowrap;
  }
  .navbar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .logo {
    width: 20%;
  }
  .main-nav-wrapper {
    width: 80%;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .nav-col {
    max-width: 50%;
  }
  .ff-navbar-nav {
    text-align: right;
  }
  .ff-navbar-nav > li.nav-item {
    display: inline-block;
    text-align: right;
    margin-left: 16px;
  }
  .ff-navbar-nav > li.nav-item a.mobile-arrow {
    display: none;
  }
  .ff-navbar-nav > li.nav-item > a {
    display: block;
  }
  .ff-navbar-nav > li.nav-item:hover .subnav-container {
    display: flex;
    opacity: 1;
    max-height: 700px;
    animation: fadein 0.5s ease-in-out;
  }
  @keyframes fadein {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 500px;
      opacity: 1;
    }
  }
  .mobile-menu,
  .mobile-tools {
    display: none;
  }
}
/*

animiertes SVG


<button class="menu-toggle" id="menu-toggle" aria-expanded="false"><span class="screen-reader-text">Menu</span>
									<svg class="icon icon-menu-toggle" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100">
										<g class="svg-menu-toggle">
											<path class="line line-1" d="M5 13h90v14H5z"/>
											<path class="line line-2" d="M5 43h90v14H5z"/>
											<path class="line line-3" d="M5 73h90v14H5z"/>
										</g>
									</svg>
							</button>


body {
  background-color: #fafafa;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;
}

button,
input[type="button"] {
	border: none;
	border-radius: 0;
	background: #000;
	color: #fff;
  cursor: pointer;
	display: inline-block;
	padding: 1em 1.5em;
	text-transform: uppercase;
	transition: all 0.25s ease-in-out;
}

.menu-toggle {
  font-size: 0.875em;
	padding: 1em;
}
.menu-toggle:focus {
	outline: thin dotted;
  outline-offset: -2px;
}

.icon {
	display: inline-block;
	fill: currentColor;
	height: 1em;
	width: 1em;
	vertical-align: middle;
	position: relative; /* Align more nicely with capital letters
	top: -0.0625em;
}
*/
/* Menu toggle styles. */
.icon-menu-toggle {
  width: 2em;
  height: 2em;
  top: 0;
}
/* Animate menu icon (lines). */
.svg-menu-toggle .line {
  opacity: 1;
  transform: rotate(0) translateY(0) translateX(0);
  transform-origin: 1em 1em;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
}
.svg-menu-toggle .line-1 {
  transform-origin: 1em 2.5em;
}
.svg-menu-toggle .line-3 {
  transform-origin: 1em 4.5em;
}
.menu-toggle.opened .svg-menu-toggle .line-1 {
  transform: rotate(45deg) translateY(0) translateX(0);
}
.menu-toggle.opened .svg-menu-toggle .line-2 {
  opacity: 0;
}
.menu-toggle.opened .svg-menu-toggle .line-3 {
  transform: rotate(-45deg) translateY(0em) translateX(0em);
}
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
/****************************************
** 02. Toolbar
****************************************/
.bottom-line {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}
.bottom-line .lang-switcher {
  display: none;
}
.bottom-line .legal {
  display: inline-block;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .bottom-line .lang-switcher {
    display: flex;
    justify-content: center;
    padding: 32px;
  }
}
@media (min-width: 992px) {
  .bottom-line .lang-switcher {
    display: inline-block;
    margin-right: 16px;
    padding: 0;
  }
  .bottom-line .legal {
    text-align: left;
    width: auto;
  }
}
.bottom-line a {
  color: var(--re-white);
}
.bottom-line a:hover {
  color: var(--re-yellow);
}
.bottom-line .lang-selected {
  color: var(--re-yellow);
}
#lang-switch-tool-top {
  display: none;
}
@media (min-width: 768px) {
  #lang-switch-tool-top {
    display: inline-block;
  }
  #lang-switch-tool-top.lang-switcher {
    white-space: nowrap;
    padding-left: 16px;
  }
  #lang-switch-tool-top a:hover {
    color: var(--re-blue);
  }
  #lang-switch-tool-top .lang-selected {
    color: var(--re-blue);
  }
}
ul.toolbar {
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  column-gap: 16px;
}
@media (min-width: 992px) {
  ul.toolbar {
    justify-content: right;
    margin-bottom: 0;
  }
}
ul.toolbar li {
  list-style: none;
  display: inline-block;
}
ul.toolbar li:hover path {
  fill: #FFE000;
}
ul.toolbar path {
  fill: #fff;
}
/****************************************
** 02. Metanavigation
****************************************/
div.metanavi ul {
  list-style: none;
}
/****************************************
** 04. Suche
****************************************/
.search-overlay {
  position: absolute;
  visibility: hidden;
  right: -12px;
  top: -19px;
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}
.searchbar-suggestionList {
  display: none;
  flex-direction: column;
}
.search-container {
  position: relative;
  top: -2px;
  margin-left: 16px;
}
@media (max-width: 759px) {
  .search-container:hover .search-overlay {
    top: -16px;
    overflow: visible;
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
  }
  .search-container:hover .search-overlay input {
    width: 70vw;
    padding-top: 8px;
  }
  .search-container:hover .searchbar-suggestionList {
    display: flex;
  }
}
@media (min-width: 760px) {
  .search-container.active .search-overlay {
    overflow: visible;
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
  }
  .search-container.active .search-overlay input {
    width: 82vw;
    padding-right: 2rem;
  }
  .search-container.active .searchbar-suggestionList {
    display: flex;
  }
}
@media (min-width: 820px) {
  .search-container.active .search-overlay input {
    width: 83vw;
  }
}
@media (min-width: 992px) {
  .search-container.active .search-overlay input {
    width: 36rem;
  }
}
.search-input {
  background-color: var(--re-grey-light);
  border-radius: 10px;
  display: flex;
  padding: 8px 12px 6px 12px;
}
.search-input input {
  width: 1rem;
  border: none;
  background: transparent;
  transition: all 0.4s ease-in-out;
}
.search-input .btn.btn-transparent {
  padding: 12px 1px 8px 1px;
}
.input-autocomplete {
  position: relative;
  display: flex;
}
.input-autocomplete input {
  line-height: 1.6;
}
.input-autocomplete ul {
  position: absolute;
  top: 32px;
  width: 100%;
  margin: 0;
  padding: 16px;
  list-style: none;
  background-color: var(--re-white);
  box-shadow: 0px 20px 20px #00000010;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.input-autocomplete ul li {
  cursor: pointer;
}
.input-autocomplete ul li:hover {
  color: var(--re-blue);
}
.input-autocomplete ul li span.fit {
  font-weight: bold;
}
div.form {
  margin: 0 0 0 0;
}
input.form-control,
textarea.form-control {
  line-height: 100%;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: var(--re-grey-light);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: center;
}
input.form-control:-webkit-autofill,
textarea.form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--re-grey-light) inset;
}
.form-group-lg textarea.form-control,
textarea.form-control {
  height: 100px;
}
textarea,
input {
  outline: none;
}
input:active:focus {
  outline: none;
  outline-color: transparent;
  outline-style: none;
}
.form-group {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .ff-formular [class*="col-"]:first-child {
    padding-left: 0;
  }
  .ff-formular [class*="col-"]:last-child {
    padding-right: 0;
  }
}
.btn.btn-default {
  padding: 12px 30px 7px 30px;
  border: none;
  margin-bottom: 15px;
  background-color: var(--re-yellow);
}
.btn.btn-default:hover {
  background-color: #666666;
}
/* Suchformular */
.ff-suchformular input[type*="image"] {
  height: 16px;
}
/* Switch */
.switch {
  position: relative;
  display: inline-block;
  /* The slider */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: var(--re-yellow);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}
.switch input:checked + .slider:before {
  transform: translateX(26px);
}
.switch .slider {
  position: absolute;
  height: 24px;
  width: 50px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--re-yellow-highlight);
  transition: 0.4s;
  /* Rounded sliders */
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: var(--re-blue);
  transition: 0.4s;
}
.switch .slider.round {
  border-radius: 24px;
}
.switch .slider.round:before {
  border-radius: 50%;
}
.custom-select {
  width: 100%;
  position: relative;
}
.custom-select select {
  appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: var(--re-grey-light);
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}
.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}
.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid var(--ff-dark);
  top: 40%;
}
.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid var(--ff-dark);
  top: 55%;
}
/********************
 **
 ** Custom Rentschler
 **
 ********************/
label {
  width: 100%;
}
.submit-button {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
.form-content {
  padding-top: 32px;
  padding-bottom: 32px;
}
.checkbox-label {
  color: var(--re-grey-fonts);
}
.checkbox-label a {
  text-decoration: underline;
}
.form-switch-text {
  margin-top: -18px;
  padding-left: 60px;
  text-align: left;
}
.form-row {
  display: flex;
}
.field-error {
  display: block;
  visibility: hidden;
  max-height: 0;
  transition: all 1s ease-in-out;
  color: var(--re-form-invalid-color);
  font-size: var(--font-size-small);
  padding-top: 5px;
  overflow: hidden;
}
.validation select:invalid ~ .field-error,
.validation input:invalid ~ .field-error {
  visibility: visible;
  max-height: 50px;
}
.validation .switch .field-error {
  position: absolute;
  max-width: 300px;
  white-space: nowrap;
  padding-top: 12px;
}
/********************
 **
 ** Download Formular
 **
 ********************/
.ff_re_downloadFormular {
  padding-bottom: 64px;
}
/********************
 **
 ** Photoswipe 
 **
 ********************/
.custom-gallery img {
  border-radius: 10px;
}
.custom-gallery .pswp-thumb-container {
  display: inline-block;
  padding-right: 8px;
  padding-bottom: 8px;
}
/********************
 **
 ** Login
 **
 ********************/
.login .col-md-12 label {
  display: block;
  float: left;
  width: 100px;
}
.login .col-md-12 input {
  border: none;
  background: #eeeeee;
  width: 200px;
  height: 28px;
}
.login .col-md-12 button {
  width: 200px;
  height: 28px;
  margin-left: 100px;
  background: var(--re-yellow);
  border: none;
  border-radius: 10px;
}
/********************
 **
 ** Details
 **
 ********************/
.details-group {
  border-top: var(--re-grey-middle) 0.1rem solid;
}
.akkordion::part(base) {
  border: none;
  border-radius: 0;
  border-bottom: var(--re-grey-middle) 0.1rem solid;
}
.schema-blue .details-group {
  border-color: var(--re-white);
}
.schema-blue .akkordion::part(base) {
  border-color: var(--re-white);
}
.details-group .akkordion[open]::part(header) {
  font-weight: bold;
}
/********************
 **
 ** Image Link
 **
 ********************/
div.image-link div.normal {
  display: inline-block;
  background-size: cover;
}
div.image-link div.hover {
  display: none;
  background-size: cover;
}
div.image-link a:hover div.normal {
  display: none;
}
div.image-link a:hover div.hover {
  display: inline-block;
}
/********************
 **
 ** Footerlist
 **
 ********************/
.footerList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footerList a {
  color: var(--re-white);
}
.footerList a:hover {
  color: var(--re-yellow);
}
.footer-items h4 {
  color: var(--re-white);
}
@media screen and (max-width: 768px) {
  .footer-grid {
    row-gap: 0;
    border-top: white 1px solid;
  }
  .footer-items {
    padding-top: 16px;
    border-bottom: white 1px solid;
  }
  .footer-items h4 {
    cursor: pointer;
  }
  .footer-items .footerList {
    padding-bottom: 16px;
  }
  .footer-items.hidden .footerList {
    display: none;
  }
}
/********************
 **
 ** News
 **
 ********************/
/********************
 **
 ** Carousel
 **
 ********************/
sl-carousel::part(pagination-item--active) {
  transform: scale(1);
  background-color: var(--re-blue);
}
.schema-blue sl-carousel::part(pagination-item--active) {
  transform: scale(1);
  background-color: var(--re-white);
}
.carousel-container-hero {
  margin-bottom: 64px;
  background-size: cover;
}
.carousel-container-hero sl-carousel {
  position: relative;
}
.carousel-container-hero sl-carousel::part(pagination) {
  position: relative;
  top: 32px;
}
.carousel-container-hero .carousel-image {
  padding-top: 16px;
}
.carousel-container-hero .carousel-image img {
  border-radius: 16px;
}
.carousel-container-hero .carousel-content {
  padding-top: 16px;
}
.carousel-container-hero h1,
.carousel-container-hero h2,
.carousel-container-hero h3,
.carousel-container-hero h4,
.carousel-container-hero h5,
.carousel-container-hero h6,
.carousel-container-hero p,
.carousel-container-hero li {
  color: var(--re-white);
}
@media screen and (min-width: 768px) {
  .carousel-container-hero {
    margin-bottom: 96px;
  }
  .carousel-container-hero sl-carousel::part(pagination) {
    position: relative;
    top: 32px;
  }
  .carousel-container-hero .carousel-content {
    padding-right: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .carousel-container-hero {
    margin-bottom: 160px;
  }
  .carousel-container-hero sl-carousel {
    aspect-ratio: auto;
  }
}
@media screen and (min-width: 1200px) {
  .carousel-container-hero {
    height: 490px;
  }
  .carousel-container-hero sl-carousel {
    top: 64px;
  }
  .carousel-container-hero sl-carousel::part(pagination) {
    position: absolute;
    left: 0;
    top: auto;
    bottom: 16px;
  }
  .carousel-container-hero .carousel-image {
    padding-top: 0;
  }
}
@media screen and (min-width: 1400px) {
  .carousel-container-hero {
    height: 560px;
  }
}
/********************
 **
 ** Carousel Content
 **
 ********************/
.carousel-container-content {
  padding-top: 32px;
  padding-bottom: 32px;
}
.carousel-content-image {
  width: 100%;
  border-radius: 16px;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.carousel-content-image img {
  object-fit: cover;
  object-position: center;
}
.carousel-content-image iframe {
  border-radius: 10px;
  overflow: hidden;
}
.carousel-content::part(navigation-button--previous) {
  color: var(--re-grey-fonts);
}
.carousel-content::part(navigation-button--next) {
  color: var(--re-grey-fonts);
}
/*
.schema-blue {
	.carousel-content::part(navigation-button--previous)  {
		color: var(--re-white);
	}
	.carousel-content::part(navigation-button--next)  {
		color: var(--re-white);
	}
}
*/
.carousel-single-item {
  padding-top: 64px;
}
.carousel-container-content sl-carousel::part(base) {
  gap: 0;
}
.carousel-container-content sl-carousel::part(pagination-item--active) {
  transform: scale(1);
}
.carousel-container-content sl-carousel::part(navigation) {
  position: relative;
}
.carousel-container-content sl-carousel::part(pagination) {
  transform: translateY(16px);
}
.carousel-container-content sl-carousel::part(navigation-button) {
  position: absolute;
  top: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.3);
  height: calc(100% - var(--sl-spacing-small));
  width: 4rem;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  color: var(--re-blue);
  font-size: 4rem;
}
.carousel-container-content sl-carousel::part(navigation-button):hover {
  background: rgba(255, 255, 255, 0.6);
}
.carousel-container-content sl-carousel::part(navigation-button--next) {
  right: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.carousel-container-content sl-carousel::part(navigation-button--previous) {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
/********************
 **
 ** Cards
 **
 ********************/
/* Simple Cards */
.card {
  border-radius: 10px;
  border: var(--re-grey-middle) solid 1px;
  margin-bottom: 32px;
  overflow: hidden;
}
.card.widget {
  min-height: 300px;
}
.card-content {
  padding: 32px;
}
.card-img img {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}
.card-svg {
  width: 20px;
  height: 20px;
}
.card-svg svg {
  fill: var(--re-blue);
}
.card-list {
  list-style-type: none;
  margin: 0;
  padding: 0 32px 32px 32px;
}
.card-list-item {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--re-grey-middle) solid 1px;
  padding: 8px 0;
}
.card-list-item:first-child {
  border-top: var(--re-grey-middle) solid 1px;
}
/* Teaser Cards */
.card-container {
  padding-bottom: 96px;
}
.card-header {
  font-size: var(--font-size);
  line-height: var(--s-1);
}
.card-number,
.card-counter {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-7);
  color: var(--re-blue);
  white-space: no-wrap;
}
.card-footer {
  font-size: var(--font-size);
  line-height: 1.2;
  min-height: 3rem;
}
.card-icon {
  width: 20%;
  margin-bottom: 16px;
}
.card-icon path {
  fill: var(--re-blue);
}
.col-lg-3 .card-icon {
  width: 30%;
}
.col-lg-4 .card-icon {
  width: 20%;
}
.col-lg-6 .card-icon {
  width: 30%;
}
@media screen and (min-width: 992px) {
  .col-lg-3 .card-icon {
    width: 30%;
  }
  .col-lg-4 .card-icon {
    width: 50%;
  }
  .col-lg-6 .card-icon {
    width: 30%;
  }
}
@media screen and (min-width: 1200px) {
  .col-lg-4 .card-icon {
    width: 40%;
  }
}
@media screen and (min-width: 1400px) {
  .col-lg-4 .card-icon {
    width: 30%;
  }
}
.card-text {
  font-family: var(--re-font-univers);
  font-size: var(--font-size-2);
  line-height: 1.2;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.re-card {
  flex: 0 0 100%;
  position: relative;
  border-radius: 20px;
  border: var(--re-grey-middle) solid 1px;
  overflow: hidden;
  margin-bottom: 32px;
  /*	&.rowspan-2 {
		height: 620px;
	}
	&.card-item-element {
	}
*/
}
.re-card .re-card-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.re-card .re-card-content.card-icon {
  padding: 32px;
  justify-items: center;
  text-align: center;
}
.re-card .re-card-content.card-facts {
  text-align: center;
}
.re-card .re-card-content.card-text {
  padding: 32px;
  text-align: center;
}
.re-card .re-card-content.card-bgbild {
  display: grid;
  justify-items: center;
}
.re-card .re-card-content.card-bgbild > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.re-card .re-card-content.card-bgbild .card-bg {
  width: 100%;
  height: 100%;
}
.re-card .re-card-content.card-bgbild img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.re-card .re-card-content.card-bgbild .card-footer {
  z-index: 1;
  align-self: end;
  margin-bottom: 16px;
}
.re-card .card-number {
  position: relative;
}
.re-card.schema-yellow {
  background-color: var(--re-yellow);
  border: none;
}
.re-card.schema-yellow .card-number {
  color: var(--re-grey-fonts);
}
.re-card.schema-yellow .card-icon path {
  fill: var(--re-grey-fonts);
}
.re-card.schema-blue {
  background-color: var(--re-blue);
  border: none;
}
.re-card.schema-blue .card-header {
  color: var(--re-white);
}
.re-card.schema-blue .card-number {
  color: var(--re-white);
}
.re-card.schema-blue .card-footer {
  color: var(--re-white);
}
.re-card.schema-blue .card-icon path {
  fill: var(--re-white);
}
.re-card.schema-blue .card-text {
  color: var(--re-white);
}
.re-card.schema-bg .card-footer {
  position: absolute;
  bottom: 16px;
  background: var(--re-white-80);
  padding: 16px;
  border-radius: 10px;
  width: 80%;
}
.card-bild {
  width: 100%;
  border-radius: 20px;
  aspect-ratio: 16/9;
}
.card-bild.aspect-square {
  aspect-ratio: 1/1;
}
.card-bild img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  object-position: center;
}
.card-richtext {
  width: 100%;
  text-align: left;
  padding-block: 16px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.card-richtext .btn {
  display: inline-block;
  position: relative;
  top: 16px;
}
.card-richtext h4 {
  color: var(--re-blue);
}
.news-teaser {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media screen and (min-width: 768px) {
  .news-teaser .re-card .teaser-content-flex,
  .news-filter-list .re-card .teaser-content-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
  }
}
.news-teaser .re-card.imageLeft .re-card-content,
.news-filter-list .re-card.imageLeft .re-card-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.news-teaser .re-card.imageLeft .card-image,
.news-filter-list .re-card.imageLeft .card-image {
  width: 50%;
  height: 100%;
}
.news-teaser .re-card.imageLeft .teaser-content-flex,
.news-filter-list .re-card.imageLeft .teaser-content-flex {
  width: 50%;
  height: 100%;
}
.news-teaser .re-card.imageLeft .card-footer,
.news-filter-list .re-card.imageLeft .card-footer {
  left: calc(50% + 32px);
}
.news-teaser .re-card.lg .card-image,
.news-filter-list .re-card.lg .card-image {
  aspect-ratio: 16/4.3;
}
.news-teaser .re-card.imageLeft.lg .card-image,
.news-filter-list .re-card.imageLeft.lg .card-image {
  aspect-ratio: 16/9;
}
a .re-card-content:hover .hoverlay {
  visibility: visible;
  opacity: 1;
}
.card-image {
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;
  display: grid;
}
.card-image picture,
.card-image > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.card-image .hoverlay {
  padding: 16px;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  background-color: var(--re-blue-90);
  transition: all 0.25s ease-in-out;
  display: grid;
  place-items: center end;
  color: var(--re-white);
  cursor: pointer;
}
.card-image .hoverlay svg {
  fill: var(--re-white);
}
.card-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.news-info {
  margin: 0;
  margin-bottom: 16px;
}
.teaser-text p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-item {
  color: var(--re-grey-middle);
  font-size: var(--font-size-small);
}
.news-item svg {
  height: 1rem;
  margin-right: 0.4rem;
  fill: var(--re-grey-middle);
}
.card-teaser {
  font-size: var(--font-size);
  text-transform: none;
  padding: 16px;
}
.card-header,
.card-footer {
  padding: 16px;
}
/********************
 **
 ** Text Icon Block
 **
 ********************/
.icon-textblock {
  display: flex;
  margin-bottom: 32px;
}
.icon-textblock .icon-svg {
  width: 20%;
  padding-right: 32px;
}
.icon-textblock .text {
  width: 80%;
}
/********************
 **
 ** Hero
 **
 ********************/
.hero {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.hero-image {
  aspect-ratio: 16/6;
}
.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center right;
}
.hero-elipse {
  display: none;
}
.hero-content {
  padding-top: 64px;
  padding-bottom: 64px;
  background: transparent linear-gradient(180deg, #00386A 0%, #006ab2 100%) 0% 0% no-repeat padding-box;
}
.hero-image-only {
  width: 100%;
  height: 100%;
}
.hero-image-only img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 992px) {
  .hero {
    aspect-ratio: 16/8;
  }
}
@media screen and (min-width: 1200px) {
  .hero {
    aspect-ratio: 16/6;
  }
}
@media screen and (min-width: 1400px) {
  .hero {
    aspect-ratio: 16/5;
  }
}
@media screen and (min-width: 992px) {
  .hero-image {
    aspect-ratio: auto;
    width: 60%;
    height: 100%;
    margin-left: 40%;
  }
  .hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
  }
  .hero-elipse {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    overflow: hidden;
  }
  .hero-elipse:after {
    content: "";
    width: 2000px;
    height: 2000px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 50%;
    background-color: lightblue;
    background: transparent linear-gradient(180deg, #00386A 0%, #006ab2 100%) 0% 0% no-repeat padding-box;
  }
  .hero-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding-top: 96px;
    background: none;
  }
  .hero-image-only {
    width: 100%;
    height: 100%;
  }
  .hero-image-only img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
/********************
 **
 ** Linkbox/Linkpool
 **
 ********************/
.linkbox {
  margin-bottom: 96px;
}
.img-area {
  position: relative;
  min-height: 520px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: end;
  background-size: cover;
  background-repeat: no-repeat;
}
.img-area.area-left {
  border-right: solid 1px var(--re-grey-middle);
  border-left: solid 1px var(--re-grey-middle);
  border-top: solid 1px var(--re-grey-middle);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.img-area.area-right {
  border-right: solid 1px var(--re-grey-middle);
  border-left: solid 1px var(--re-grey-middle);
  border-top: solid 1px var(--re-grey-middle);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media screen and (min-width: 992px) {
  .img-area.area-left {
    border-right: none;
    border-bottom: solid 1px var(--re-grey-middle);
    border-left: solid 1px var(--re-grey-middle);
    border-top: solid 1px var(--re-grey-middle);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0;
  }
  .img-area.area-right {
    border-left: none;
    border-bottom: solid 1px var(--re-grey-middle);
    border-right: solid 1px var(--re-grey-middle);
    border-top: solid 1px var(--re-grey-middle);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
  }
}
.link-area {
  list-style: none;
  min-height: 520px;
  height: 100%;
  padding: 32px;
}
.link-area.area-left {
  border-bottom: solid 1px var(--re-grey-middle);
  border-left: solid 1px var(--re-grey-middle);
  border-right: solid 1px var(--re-grey-middle);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.link-area.area-right {
  border-bottom: solid 1px var(--re-grey-middle);
  border-left: solid 1px var(--re-grey-middle);
  border-right: solid 1px var(--re-grey-middle);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media screen and (min-width: 992px) {
  .link-area.area-left {
    border-right: 0;
    border-bottom: solid 1px var(--re-grey-middle);
    border-left: solid 1px var(--re-grey-middle);
    border-top: solid 1px var(--re-grey-middle);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0;
  }
  .link-area.area-right {
    border-left: 0;
    border-bottom: solid 1px var(--re-grey-middle);
    border-right: solid 1px var(--re-grey-middle);
    border-top: solid 1px var(--re-grey-middle);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0;
  }
}
.linkbox-content {
  padding: 32px;
  margin: 32px;
  background: #ffffffcc;
  border-radius: 10px;
}
@media screen and (min-width: 1200px) {
  .linkbox-content {
    width: 70%;
  }
}
.linkbox-item a {
  display: block;
}
.linkbox-item a:hover {
  color: var(--re-blue);
}
.linkbox-item .link-headline svg {
  fill: var(--re-blue);
  margin-top: -16px;
}
.linkbox-item .link-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.linkbox-item .link-content {
  padding-right: 10%;
}
.linkbox-item h4,
.linkbox-item p {
  margin-bottom: 8px;
}
/********************
 **
 ** Portrait
 **
 ********************/
.portrait-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
}
.portrait-wrapper .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 214px;
  height: 214px;
  border-radius: 50%;
  background: transparent linear-gradient(133deg, #FFE00019 0%, var(--re-yellow) 75%, var(--re-yellow) 100%) 0% 0% no-repeat padding-box;
}
.portrait-wrapper .inner {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  overflow: hidden;
}
.portrait-wrapper .inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/********************
 **
 ** Boxed Content
 **
 ********************/
.boxed-content {
  display: flex;
  justify-content: center;
}
.boxed {
  border-radius: 20px;
  overflow: hidden;
  background-color: #ffffffee;
  padding-block: 96px 64px;
  padding-inline: 64px;
  border: 1px solid var(--re-grey-middle);
}
@media screen and (min-width: 992px) {
  .boxed {
    border: none;
  }
  .boxed.center {
    width: 70%;
  }
  .boxed.left {
    width: 50%;
    margin-right: 50%;
  }
}
/********************
 **
 ** Header Message
 **
 ********************/
#header-message {
  position: relative;
  display: none;
  background-color: var(--re-yellow);
}
#header-message.show {
  display: block;
}
#header-message .align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
@media screen and (max-width: 759px) {
  #header-message .align-right {
    padding-right: 40px;
  }
}
#header-message .message-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
#header-message .message-content p,
#header-message .message-content h1,
#header-message .message-content h2,
#header-message .message-content h3,
#header-message .message-content h4,
#header-message .message-content h5,
#header-message .message-content h6 {
  margin: 0;
}
#header-message .message-more {
  display: block;
  padding: 12px 20px 8px 20px;
  border-radius: 10px;
  background-color: var(--re-yellow-highlight);
}
/********************
 **
 ** Breadcrumb
 **
 ********************/
.breadcrumb {
  border-bottom: 1px var(--re-grey-between) solid;
}
.breadcrumb-list {
  padding: 8px 0 4px 0;
  display: flex;
  list-style-type: none;
  margin: 0;
}
.breadcrumb-list .seperator::before {
  content: ' > ';
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.breadcrumb-list li,
.breadcrumb-list a {
  font-size: var(--font-size-small);
  color: var(--re-grey-between);
}
.breadcrumb-list .leaf {
  display: none;
}
@media screen and (min-width: 992px) {
  .breadcrumb-list .leaf {
    display: block;
  }
}
.breadcrumb-list a:hover {
  color: var(--re-grey-fonts);
}
/********************
 **
 ** Row Image und Text
 **
 ********************/
.row-image-text.left {
  display: flex;
  flex-direction: column-reverse;
}
.col-text-container {
  width: 100%;
}
.col-text-container .row-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}
.col-img {
  padding: 32px;
}
.col-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.img-wrapper {
  border-radius: 10px;
  aspect-ratio: 16/9;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .col-img {
    width: 50%;
    padding: 0;
  }
  .col-img .img-wrapper {
    border-radius: 0;
  }
  .col-img.left {
    padding-right: 64px;
  }
  .col-img.left .img-wrapper {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .col-img.right {
    padding-left: 64px;
  }
  .col-img.right .img-wrapper {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .row-image-text {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .row-image-text.left {
    flex-direction: row;
  }
  .col-text-container {
    width: 480px;
  }
  .col-text-container.left {
    width: 50%;
    padding-left: calc(50% - 480px);
  }
  .col-text-container .row-text {
    padding: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .col-text-container {
    width: 570px;
  }
  .col-text-container.left {
    padding-left: calc(50% - 570px);
  }
}
@media screen and (min-width: 1400px) {
  .col-text-container {
    width: 660px;
  }
  .col-text-container.left {
    padding-left: calc(50% - 660px);
  }
}
/********************
 **
 ** Card with page turn 
 **
 ********************/
.flip-card {
  width: 100%;
  margin-bottom: 32px;
  border-radius: 10px;
  border: 1px solid var(--re-grey-middle);
  overflow: hidden;
}
.flip-card-front,
.flip-card-back {
  background-color: var(--re-white);
}
.flip-card-front {
  padding-bottom: 16px;
}
.flip-card-front h3,
.flip-card-front h4,
.flip-card-front h5,
.flip-card-front h6 {
  color: var(--re-blue);
}
.flip-card-back {
  padding: 16px;
}
.card-social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
}
.card-social-icons li {
  width: 18px;
}
.card-social-icons li path {
  fill: var(--re-blue);
}
.img-pageflip {
  width: 100%;
}
.flip-card-front .card-richtext {
  justify-content: start;
}
.flip-card-front .card-richtext .btn-flip {
  display: none;
}
@media screen and (min-width: 768px) {
  .flip-card-front .card-richtext .btn-flip {
    display: block;
    position: absolute;
    bottom: 16px;
    top: auto;
  }
  .flip-card {
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
    margin-bottom: 0;
    padding-bottom: 26px;
    border-radius: 0;
    border: none;
    overflow: visible;
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  .flip-card.flip .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    border-radius: 10px;
    border: 1px solid var(--re-grey-middle);
    overflow: hidden;
  }
  .flip-card-front {
    display: flex;
    flex-direction: column;
  }
  .flip-card-back {
    max-height: 650px;
    min-height: 250px;
    background-color: var(--re-white);
    transform: rotateY(180deg);
    box-shadow: 10px 10px 50px #00000010;
    position: relative;
    padding: 0;
    padding-block: 64px 32px;
    padding-inline: 32px;
  }
  .img-pageflip {
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}
/********************
 **
 ** News Filter 
 **
 ********************/
.searchbar-natural,
.news-filter {
  position: relative;
  width: 100%;
  height: 450px;
  filter: blur(0);
  transition: 0.25s all ease-in-out;
}
.searchbar-natural.visible .natural-language-main-list,
.news-filter.visible .natural-language-main-list {
  filter: blur(4px);
}
.searchbar-natural.visible .natural-language-overlay.active,
.news-filter.visible .natural-language-overlay.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  height: 100%;
}
.natural-language-main-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.natural-language-main-list li {
  margin: 0.5rem;
  padding: 0;
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  font-weight: var(--re-font-weight-normal);
  color: var(--re-white);
}
.natural-language-main-list li a {
  font: inherit;
  color: var(--re-yellow);
  border-bottom: 2px solid var(--re-yellow);
}
@media screen and (min-width: 768px) {
  .natural-language-main-list {
    flex-direction: row;
  }
}
.suggestionList,
.natural-language-select {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 16px;
  list-style-type: none;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.suggestionList::-webkit-scrollbar-track,
.natural-language-select::-webkit-scrollbar-track {
  background: #fff;
}
.suggestionList::-webkit-scrollbar,
.natural-language-select::-webkit-scrollbar {
  width: 2px;
}
.suggestionList::-webkit-scrollbar-thumb,
.natural-language-select::-webkit-scrollbar-thumb {
  background: #999 !important;
}
.suggestionList li,
.natural-language-select li {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  font-weight: var(--re-font-weight-normal);
  line-height: 1;
  color: var(--re-white);
  cursor: pointer;
  word-break: break-all;
}
.suggestionList li.selected,
.natural-language-select li.selected {
  color: var(--re-yellow);
}
.suggestionList li span.fit,
.natural-language-select li span.fit {
  font-weight: bold;
}
.natural-language-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 28px;
  opacity: 0;
  filter: blur(0);
  transform: translate3d(0, -450px, 0);
  transition: 0.25s all ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.natural-language-tools {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 12px;
  height: 12px;
}
.natural-language-tools a {
  display: block;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.search-controls {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--re-white);
}
@media screen and (min-width: 768px) {
  .search-controls {
    width: 60%;
  }
}
.search-controls input {
  background: transparent;
  border: none;
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  font-weight: var(--re-font-weight-normal);
  color: var(--re-white);
}
.search-control-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: block;
  width: 32px;
  height: 23px;
}
.suggestionList {
  position: absolute;
  min-height: 300px;
  top: 56px;
  background-color: #00499270;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: normal;
}
/********************
 **
 ** Table
 **
 ********************/
table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  border-width: 0;
}
thead th {
  vertical-align: top;
}
thead td,
thead td {
  font-weight: 600;
}
thead {
  border-top: 1px solid var(--re-grey-fonts);
  border-bottom: 1px solid var(--re-grey-between);
}
tbody tr {
  border-bottom: 1px solid var(--re-grey-between);
}
tbody td::before {
  display: none;
}
table td {
  padding: 8px;
}
@media screen and (max-width: 760px) {
  thead {
    display: none;
  }
  table,
  tbody,
  tbody tr,
  tbody td,
  caption {
    width: auto !important;
    word-break: break-all;
  }
  table {
    background-color: transparent;
    height: auto !important;
  }
  table tr {
    border-bottom: none;
    border-top: 1px solid var(--re-grey-fonts);
    border-bottom: 1px solid var(--re-grey-fonts);
    height: auto !important;
  }
  table td {
    height: auto !important;
  }
  tbody td {
    border-bottom: 1px solid var(--re-grey-between);
    width: auto !important;
    height: auto !important;
  }
  .table-wrapper {
    max-width: 568px;
  }
  tbody tr td::before {
    font-weight: 600;
    display: block;
    align-self: baseline;
  }
  .schema-blue tbody td {
    border-bottom: 1px solid var(--re-white);
  }
  .schema-blue table tr {
    border-top: none;
    border-bottom: none;
  }
}
/********************
 **
 ** Mega Card
 **
 ********************/
.mega-card {
  background-color: var(--re-grey-light);
  border-radius: 20px;
  padding: 64px;
}
.mega-card-img {
  max-width: 100%;
}
/********************
 **
 ** timeline
 **
 ********************/
.swiper-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  touch-action: pan-x;
}
.swiper-container .swiper {
  position: relative;
}
.swiper-container .btn-scroll {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 100%;
  background-color: #ffffff99;
}
.swiper-container .btn-scroll svg {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--re-blue);
}
.swiper-container .prev {
  left: 0;
}
.swiper-container .next {
  right: 0;
}
.swiper-wrapper {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  overflow-y: auto;
  justify-content: center;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  padding: 20px;
}
.swiper-container::-webkit-scrollbar-track {
  background: #a8a8a8b6;
}
.swiper-container::-webkit-scrollbar {
  height: 2px;
}
.swiper-container::-webkit-scrollbar-thumb {
  background: #4F4F4F !important;
}
.swiper-slide {
  position: relative;
  width: 330px;
  margin-bottom: 16px;
}
.swiper-slide .re-card {
  position: relative;
  height: 90%;
  padding-bottom: 16px;
  overflow: visible;
  cursor: pointer;
}
.swiper-slide .timeline {
  border-bottom: 1px solid #eee;
  position: absolute;
  bottom: 0;
  left: -8px;
  width: calc(100% + 16px);
}
.swiper-slide .timeline::after {
  position: absolute;
  left: 50%;
  top: -6px;
  content: '';
  border-radius: 16px;
  width: 12px;
  height: 12px;
  border: 1px solid #eee;
  background-color: #fff;
  transform: translateX(-50%);
}
.swiper-slide .timeline.start {
  width: calc(50% + 16px);
  left: calc(50% - 6px);
}
.swiper-slide .timeline.start::after {
  left: 0;
  transform: translateX(0);
}
.swiper-slide .timeline.end {
  width: calc(50% + 16px);
  left: -10px;
}
.swiper-slide .timeline.end::after {
  right: 0;
  left: auto;
  transform: translateX(0);
}
.swiper-slide:hover .re-card {
  border-color: #fff;
  box-shadow: 10px 10px 30px #00000020;
}
.swiper-slide:hover .re-card::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
  box-shadow: 10px 10px 30px #00000020;
}
.swiper-slide:hover .timeline::after {
  border-color: #00386A;
  background-color: #00386A;
}
.swiper-slide:hover h4,
.swiper-slide:hover h5 {
  color: var(--re-blue);
}
/********************
 **
 ** Events
 **
 ********************/
.event-filter-list {
  list-style: none;
  margin-bottom: 32px;
  padding-left: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid var(--re-grey-light);
  border-bottom: 1px solid var(--re-grey-light);
}
.event-element {
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--re-grey-light);
}
.event-element:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.event-dates {
  width: 10%;
  display: flex;
  flex-direction: column;
  padding-top: 14px;
}
.event-day {
  font-family: var(--re-font-univers-condensed);
  font-size: var(--font-size-5);
  color: var(--re-blue);
  line-height: 0.5;
}
.event-month-year {
  font-family: var(--re-font-univers);
  font-size: var(--font-size-small);
  color: var(--re-blue);
}
.event-teaser {
  width: 80%;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
}
.event-url {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
  align-self: stretch;
}
.event-url svg {
  fill: var(--re-blue);
}
/********************
 **
 ** Search Results
 **
 ********************/
.resultheadline {
  padding-bottom: 32px;
  border-bottom: var(--re-grey-light) 0.1rem solid;
}
.searchResult {
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: var(--re-grey-light) 0.1rem solid;
}
.load-more {
  padding-top: 32px;
  padding-bottom: 32px;
}
/********************
 **
 ** Vertical Align Element
 **
 ********************/
.valign-text,
.valign-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block-end: 32px;
}
@media screen and (min-width: 992px) {
  .valign-text,
  .valign-img {
    padding-block-end: 0;
    padding-inline: 32px;
  }
}
/****************************************
** 02. Sidebar
****************************************/
/* New */
#sidebar {
  display: none;
  position: fixed;
  top: 130px;
  right: -220px;
  width: 270px;
  z-index: 1100;
  transition: all 0.5s ease-in;
}
#sidebar:hover {
  right: 0;
}
#sidebar.hold {
  right: 0;
}
#sidebar ul {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--re-blue-15);
  list-style-type: none;
  border-radius: 15px 0 0 15px;
  box-shadow: 10px 10px 50px #00000010;
  overflow: hidden;
}
#sidebar ul li a.aside-link {
  padding-left: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-repeat: no-repeat;
  line-height: 1;
  transition: all 0.2s ease-in;
  color: var(--re-blue-90);
}
#sidebar ul li a.aside-link.active {
  color: var(--re-blue);
}
#sidebar ul li a.aside-link.active svg {
  fill: var(--re-blue);
}
#sidebar ul li a.aside-link:hover {
  color: var(--re-blue);
}
#sidebar ul li a.aside-link:hover svg {
  fill: var(--re-blue);
}
#sidebar .aside-icon {
  display: block;
  width: 35px;
  height: 35px;
  padding: 5px;
}
#sidebar .aside-icon svg {
  width: 100%;
  height: 100%;
  fill: var(--re-blue-90);
}
#sidebar .aside-name {
  display: block;
  padding: 5px 0 0 5px;
}
@media screen and (min-width: 768px) {
  #sidebar {
    display: block;
  }
}
/****************************************
** 10. Video Hero 
****************************************/
.hero-image video {
  display: block;
}
.slideDown {
  animation-name: slideDown;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible;
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease;
  visibility: visible;
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
}
.slideLeft {
  animation-name: slideLeft;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  visibility: visible;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.slideRight {
  animation-name: slideRight;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  visibility: visible;
}
@keyframes slideRight {
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.slideExpandUp {
  animation-name: slideExpandUp;
  animation-duration: 1.6s;
  animation-timing-function: ease-out;
  visibility: visible;
}
@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scaleX(1);
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  visibility: visible;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.komplex {
  animation-name: komplex;
  animation-duration: 1.6s;
  animation-timing-function: ease-out;
  visibility: visible;
}
@keyframes komplex {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
.stretchLeft {
  animation-name: stretchLeft;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  visibility: visible;
}
@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}
.stretchRight {
  animation-name: stretchRight;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  transform-origin: 0% 0%;
  visibility: visible;
}
@keyframes stretchRight {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}
/********************
 **
 ** Buttons
 **
 ********************/
div.action-button {
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
a.action:link {
  text-decoration: none;
}
a.action-sm {
  line-height: 25px;
  font-size: 16px;
  padding: 5px 13px 5px 13px;
}
a.action-md {
  line-height: 38px;
  font-size: 25px;
  padding: 8px 20px 8px 20px;
}
a.action-lg {
  line-height: 60px;
  font-size: 40px;
  padding: 10px 40px 10px 40px;
}
