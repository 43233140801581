@charset "UTF-8";
:root {
  --re-white: #ffffff;
  --re-white-80: #ffffff80;
  --re-blue: #006AB2;
  --re-blue-90: #006ab290;
  --re-blue-15: #d9e9f4;
  --re-yellow: #FFE000;
  --re-yellow-highlight: #fff7bf;
  --re-grey-light: #eaeaea;
  --re-grey-between: #d3d3d3;
  --re-grey-middle: #b2b2b2;
  --re-grey-dark: #9c9c9c;
  --re-grey-fonts: #3e3e40;
  --re-font-univers: "Univers LT Std";
  --re-font-univers-bold: "Univers LT Std Bold";
  --re-font-univers-condensed: "Univers 57 Condensed";
  --re-font-univers-oblique: "Univers LT Std Oblique";
  --re-font-weight-normal: 300;
  --re-line-height: 1.6;
  --re-body-font-family: var(--re-font-univers);
  --re-body-font-size: var(--font-size);
  --re-body-font-weight: var(--re-font-weight-normal);
  --re-body-line-height: var(--re-line-height);
  --re-body-color: var(--re-grey-fonts);
  --re-body-bg: var(--re-white);
  --re-heading-color: inherit;
  --re-link-color: var(--re-blue);
  --re-link-decoration: none;
  --re-link-decoration-hover: underline;
  --re-link-hover-color: var(--re-blue);
  --re-border-radius: @radius;
  --re-border-radius-16: @radius-16;
  --re-border-radius-20: @radius-20;
  --re-border-radius-sm: 0.25rem;
  --re-border-radius-lg: 0.5rem;
  --re-border-radius-xl: 1rem;
  --re-border-radius-xxl: 2rem;
  --re-border-radius-2xl: var(--bs-border-radius-xxl);
  --re-form-valid-color: #198754;
  --re-form-valid-border-color: #198754;
  --re-form-invalid-color: #dc3545;
  --re-form-invalid-border-color: #dc3545;

// https://modern-fluid-typography.vercel.app
// 13
--font-size-small: 13px;
// 16
  --font-size: ~"clamp(0.813rem, 1vw + 1rem, 1rem)";
// 19
  --font-size-1: ~"clamp(0.938rem, 1vw + 1rem, 1.188rem)";
// 23
  --font-size-2: ~"clamp(1rem, 1vw + 1rem, 1.438rem)";
// 30
  --font-size-3: ~"clamp(1.375rem, 1vw + 1.25rem, 1.875rem)";
// 33
  --font-size-4: ~"clamp(1.375rem, 1vw + 1rem, 2.063rem)";
// 50
  --font-size-5: ~"clamp(1.25rem, 1vw + 1.75rem, 3.125rem)";
// 58
  --font-size-6: ~"clamp(1.188rem, 1vw + 1.75rem, 3.625rem)";
// 120
  --font-size-7: ~"clamp(6.563rem, 1vw + 3rem, 7.5rem)";

}

