
.shadow(@rgba:rgba(0,0,0,0.12)) {
	box-shadow: 8px 10px 14px -3px @rgba;
}

/****************************************
** 08. Show/Hide
****************************************/

.hide() {
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
}
.show() {
    visibility: visible;
    overflow: visible;
    opacity: 1;
}

/****************************************
** 08. Gutter
****************************************/

.padding-gutter(@val: 1) {
	padding-top:@gutter * @val;
	padding-bottom:@gutter * @val;
	padding-left:@gutter * @val;
	padding-right:@gutter * @val;
}

.padding-y-gutter(@val: 1) {
	padding-top:@gutter * @val;
	padding-bottom:@gutter * @val;
}
.padding-t-gutter(@val: 1) {
	padding-top:@gutter * @val;
}
.padding-b-gutter(@val: 1) {
	padding-bottom:@gutter * @val;
}

.padding-x-gutter(@val: 1) {
	padding-left:@gutter * @val;
	padding-right:@gutter * @val;
}

.padding-rbl-gutter(@val: 1) {
	padding-bottom:@gutter * @val;
	padding-left:@gutter * @val;
	padding-right:@gutter * @val;
}

.margin-y-gutter(@val: 1) {
	margin-top:@gutter * @val;
	margin-bottom:@gutter * @val;
}
.margin-x-gutter(@val: 1) {
	margin-left:@gutter * @val;
	margin-right:@gutter * @val;
}

.margin-n-y-gutter(@val: 1) {
	margin-top:@n-gutter * @val;
	margin-bottom:@n-gutter * @val;
}
.margin-n-x-gutter(@val: 1) {
	margin-left:@n-gutter * @val;
	margin-right:@n-gutter * @val;
}
