/*<link rel="preload" as="font">*/
@font-face{
    /*Univers 55, 55 Roman*/
    font-family:"Univers LT Std";
    src:url("/fonts/UniversPro55Roman.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face{
    /*Univers 57 Condensed, Regular*/
    font-family:"Univers 57 Condensed";
    src:url("/fonts/UniversPro57Condensed.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face{
    /*Univers LT Std, 65 Bold*/
    font-family:"Univers LT Std Bold";
    src:url("/fonts/UniversPro65Bold.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face{
    /*Univers LT Std, 55 Oblique*/
    font-family:"Univers LT Std Oblique";
    src:url("/fonts/UniversPro55Oblique.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// 50
h1 {
    font-family: var(--re-font-univers-condensed);
    font-size: var(--font-size-5);
    text-transform: uppercase;
    color: var(--re-blue);
    line-height: 1;
    margin-bottom: @gutter-x2;
    hyphens: auto;
    -webkit-hyphens: auto;    
}
// 50
h2 {
    font-family: var(--re-font-univers-condensed);
    font-size: var(--font-size-5);
    text-transform: uppercase;
    color: var(--re-blue);
    line-height: 1;
    margin-bottom: @gutter;
    hyphens: auto;
    -webkit-hyphens: auto;    
}
// 50
h3 {
    font-family: var(--re-font-univers-condensed);
    font-size: var(--font-size-6);
    text-transform: uppercase;
    color: var(--re-blue);
    line-height: 1.1;
    margin-bottom: @gutter;
    hyphens: auto;
    -webkit-hyphens: auto;    
}
// 30
h4 {
    font-family: var(--re-font-univers-condensed);
    font-size: var(--font-size-2);
    color: var(--re-grey-fonts);
    line-height: 1.1;
    margin-bottom: @gutter;
    hyphens: auto;
    -webkit-hyphens: auto;    
}
// 23
h5 {
    font-size: var(--font-size-2);
    color: var(--re-grey-fonts);
    margin-bottom: @gutter;
    text-transform: uppercase;
    hyphens: auto;
    -webkit-hyphens: auto;
}
// 19
h6 {
    font-size: var(--font-size-1);
    color: var(--re-grey-fonts);
    margin-bottom: @gutter;
    text-transform: uppercase;
}
p {
    font-family: var(--re-font-univers);
    font-size: var(--font-size);
    line-height: var(--re-line-height);
    font-weight: var(--re-font-weight-normal);
    color: var(--re-grey-fonts);
    margin-bottom: @gutter;
    a {
        font-family: inherit;
        font-size: inherit;
    }
    strong {
        font-family: var(--re-font-univers-bold);
        font-size: inherit;
        font-weight: 400;
    }
    i {
        font-family: inherit;
        font-size: inherit;
    }
}
 i, em {
    font-family: var(--re-font-univers-oblique);
    color:inherit;
 }
 strong {
    font-family: var(--re-font-univers-bold);
    color:inherit;
 }
 li {
    font-family: var(--re-font-univers);
    font-size: var(--font-size);
    line-height: var(--re-line-height);
    color: var(--re-grey-fonts);
 }
 a {

 }